import React from 'react';
import { FaStar } from 'react-icons/fa';

const Review = ({ rating, title, content, author }) => (
  <div className="bg-yellow-50 p-6 rounded-lg shadow-md">
    <div className="flex text-orange-500 mb-2">
      {[...Array(5)].map((_, i) => (
        <FaStar key={i} className={i < rating ? 'text-orange-500' : 'text-gray-300'} />
      ))}
    </div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 mb-4">{content}</p>
    <p className="text-gray-800 font-medium">{author}</p>
  </div>
);

const ReviewSection = ({ reviews }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
      {reviews.map((review, index) => (
        <Review key={index} {...review} />
      ))}
    </div>
  );
};

export default ReviewSection;