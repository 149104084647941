import servieimg1 from "../images/slider1.jpeg";

const locationsData = [
    {
      id: 1,
      slug: "sydney",
      breadcrumbItems: [
        { label: "Home", href: "/" },
        { label: "Services", href: "/Services" },
      ],
      breadTitle: "House Cleaning Sydney",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 2,
      slug: "newcastle",
      breadTitle: "House Cleaning Newcastle",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 3,
      slug: "central-coast",
      breadTitle: "House Cleaning Central Coast",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 4,
      slug: "wollongong",
      breadTitle: "House Cleaning in Wollongong",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 5,
      slug: "maitland",
      breadTitle: "House Cleaning in Maitland",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 6,
      slug: "tweed-heads",
      breadTitle: "House Cleaning in Tweed Heads",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 7,
      slug: "wagga-wagga",
      breadTitle: "House Cleaning in Wagga Wagga",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 8,
      slug: "albury",
      breadTitle: "House Cleaning in Albury",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 9,
      slug: "port-macquarie",
      breadTitle: "House Cleaning in Port Macquarie",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    {
      id: 10,
      slug: "tamworth",
      breadTitle: "House Cleaning in Tamworth",
      breadDescription: "We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years.",
      backgroundImageSrc: servieimg1,
      whyHireUsTitle: "Why hire us for your house cleaning in Sydney?",
      whyHireUsDescription: "With decades of experience, Absolute Domestics is one of Australia's most reliable house-cleaning services. You can trust us to take the best care of your home. Here's why we're the right choice for you:",
      benefits: [
        {
          title: "Reclaim your precious time",
          description: "Reclaim your free time by hiring a professional cleaning service in Sydney and use it to spend time with your loved ones and do the activities you enjoy."
        },
        {
          title: "Experienced and vetted cleaners",
          description: "Our Sydney cleaners are experienced, background-checked, and fully insured for your peace of mind."
        }, 
        {
          title: "Customized cleaning plans",
          description: "We tailor our cleaning services to meet your specific needs and preferences in Sydney."
        }
      ],
      servicesTitle: "House cleaning services we provide in Sydney",
      servicesDescription: "We offer comprehensive cleaning services to keep your Sydney home as pristine as when you first bought it. Here's a list of services we offer for every clean:",
      servicesList: ["Dusting", "Linen changing", "Bathroom cleaning", "Vacuuming", "Kitchen cleaning", "Washing"],
      cleaningServicesTitle: "Cleaning services we offer in Sydney",
      pricingOptions: [
        {
          title: "Regular Clean",
          price: "from $38 p/h",
          description: "Book an ongoing weekly or fortnightly clean for your Sydney home and never worry about the general chores again!",
          features: [
            "Cleaners Police Checked and trained",
            "Customised cleaning to suit your needs",
            "Contractors are covered by Public Liability Insurance",
          ],
          isPopular: true,
        },
        {
          title: "Once-Off Clean",
          description: "Our move out and spring cleans take the hard work out of a big cleaning job in Sydney by using our expert one-off cleaners.",
          features: [
            "Highly trained end of lease and spring cleaners",
            "All cleaning equipment supplied",
            "Full clean or partial clean depending on your time or budget",
          ],
        },
      ],
      howToStartTitle: "How to get a Sydney cleaning service started",
      howToStartDescription: "We've primed Sydney-based house cleaners to commence their duties immediately after we green-light your cleaning request. Getting started is a breeze; just follow these three simple steps:",
      steps: [
        {
          number: 1,
          title: "Book online or over the phone",
          description: "Book in under a minute on our user-friendly online platform, or have a chat with our friendly Sydney customer service team who will ensure the perfect match for your cleaning requirements."
        },
        {
          number: 2,
          title: "Arrange a time with your cleaner",
          description: "Once an area coordinator has matched you with your dream cleaner in Sydney, it's as simple as contacting them to set up the first cleaning appointment or arrange a meeting to discuss the details."
        },
        {
          number: 3,
          title: "Enjoy your clean home",
          description: "Sit back, relax, and enjoy your spotless Sydney home while our professional cleaners take care of the hard work."
        }
      ],
      faqTitle: "Deciding to Hire a Cleaner in Sydney",
      faqDescription: "Hiring a cleaner in Sydney has been made easy and affordable with a number of services available to suit your needs. Let us walk you through just a few of the benefits you will enjoy by using our home cleaning agency in Sydney.",
      faqItems: [
        {
          title: "Why should I hire a home cleaner in Sydney?",
          content: "Time is precious, especially in a bustling city like Sydney. For many, the cost of hiring a cleaning company is minimal compared to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time exploring Sydney, taking part in events, or even earning more hours at work."
        },
        {
          title: "What are the benefits of using a home cleaning agency in Sydney?",
          content: "Home cleaning agencies in Sydney offer professional services, reliable scheduling, and often have insurance and background checks for their employees. This gives you peace of mind and consistent, high-quality cleaning services."
        },
        {
          title: "How often should I schedule house cleaning in Sydney?",
          content: "The frequency of house cleaning in Sydney depends on your lifestyle and needs. Some people prefer weekly cleanings, while others opt for bi-weekly or monthly services. We can help you determine the best schedule for your Sydney home."
        },
        {
          title: "Are your cleaning products safe for children and pets in Sydney homes?",
          content: "Yes, we use eco-friendly and pet-safe cleaning products in all Sydney homes. If you have specific product preferences or sensitivities, just let us know and we'll accommodate your needs."
        }
      ],
      contactFormTitle: "Contact Us About Sydney Cleaning Services",
      contactFormDescription: "Have questions about our Sydney cleaning services? Send us a message and we'll get back to you!"
    },
    // Add more locations here following the same structure
  ];
  
  export default locationsData;