import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import { Row, Col } from "antd";
import { Link } from 'react-router-dom';
import Testimonial from '../../components/Testimonial';

const ServiceDetails = ({ serviceData }) => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Services", href: "/Services" },
    { label: serviceData.breadTitle },
  ];

  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title={serviceData.breadTitle}
        description={serviceData.breadDescription}
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={serviceData.servbreadBgImage}
      />
      <div className="bg-white">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 md:pl-6">
              <h5 className="mb-4 text-sm tracking-widest text-yellow-600">Why Choose Us</h5>
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-4">
                {serviceData.aboutTitle}
              </h2>
              {serviceData.aboutContent.map((paragraph, index) => (
                <p key={index} className="text-gray-600 mb-4">{paragraph}</p>
              ))}
              <Link to="/services" className="mt-3 inline-block bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">Learn More</Link>
            </div>
            <div className="md:w-1/2 mb-6 md:mb-0">
              <img src={serviceData.aboutImage} alt="" className="w-full h-auto rounded-lg md:ms-20 "/>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:pr-6">
              Why hire us for your {serviceData.title}?
            </h2>
            <p className="text-gray-600 text-sm md:text-base lg:text-lg mb-6">
            {serviceData.hireContent}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-5">
            {serviceData.benefits.map((benefit, index) => (
              <div key={index} className="flex flex-col items-start shadow-lg p-10 rounded-lg">
                <FaCheckCircle className="w-10 h-10 text-green-500 mb-2" />
                <h3 className="text-2xl mb-2 font-semibold">{benefit.title}</h3>
                <p className="text-lg text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* what can be included */}
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-2 sm:mb-5">What can be included?</h2>
          <p className="text-sm sm:text-base mb-4 sm:mb-12 text-center">
            {serviceData.includeContent}
          </p>

          <div className="bg-gray-50 rounded-lg p-4">
            <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-y-6 gap-x-5">
              {serviceData.services.map((service, index) => (
                  <div key={index} className="inline-block text-center shadow-md rounded-lg p-4 bg-yellow-50">
                    <div className="w-24 h-24 p-6 bg-white shadow-lg mb-4 mx-auto rounded-full">
                      <img src={service.img} className="w-full h-auto object-cover" />
                    </div>
                    <span className="text-lg lg:text-xl xl:text-2xl">{service.name}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <div className="sticky top-48 md:pr-20">
                <h2 className="text-4xl font-bold mb-6">Why Choose askJay for Your {serviceData.breadTitle}.</h2>
                <p className="mb-6 text-lg">
                    {serviceData.howstartContent}
                </p>
                <Link to="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">Book Your Service</Link>
              </div>
            </Col>
            <Col xs={24} md={12}>
              {serviceData.steps.map((step) => (
                <Row key={step.number} gutter={[16, 24]} className="mb-6">
                  <Col xs={24} sm={24} md={24} className="flex">
                    <div className="mr-3">
                      <span className="w-10 h-10 bg-green-500 text-white rounded-full flex items-center justify-center text-xl font-bold">
                        {step.number}
                      </span>
                    </div>
                    <div>
                      <h3 className="text-2xl font-semibold mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 text-lg">{step.description}</p>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </div>
      <Testimonial/>
      <div className="py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[32, 32]}>
            <Col xs={24} md={8}>
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
                Areas we serve {serviceData.breadTitle}
              </h2>
              <p className="text-gray-600 mb-6 text-lg">
                Our {serviceData.breadTitle} are available across Australia, including the surrounding suburbs.
              </p>
            </Col>
            <Col xs={24} md={16}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {serviceData.serviceAreas.map((area, index) => (
                  <a 
                    key={index} 
                    href="#" 
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    {area}
                  </a>
                ))}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      
    </>
  );
};

export default ServiceDetails;