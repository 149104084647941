import React from 'react';
import { FaCheckCircle, FaArrowRight } from 'react-icons/fa';
import incicon1 from '../images/icons/1.png';
import incicon2 from '../images/icons/2.png';
import incicon3 from '../images/icons/3.png';
import incicon4 from '../images/icons/4.png';
import incicon5 from '../images/icons/5.png';
import incicon6 from '../images/icons/6.png';
import incicon7 from '../images/icons/7.png';
import incicon8 from '../images/icons/8.png';
import incicon9 from '../images/icons/9.png';
import incicon10 from '../images/icons/10.png';
import incicon11 from '../images/icons/11.png';
import incicon12 from '../images/icons/12.png';
import incicon13 from '../images/icons/13.png';
import incicon14 from '../images/icons/14.png';
import incicon15 from '../images/icons/15.png';
import incicon16 from '../images/icons/16.png';
import incicon17 from '../images/icons/17.png';
import incicon18 from '../images/icons/18.png';
import incicon19 from '../images/icons/19.png';


const Pricing = () => {
  const services = [
    { name: "Dusting",
      img: incicon1
     },
    { name: "Linen changing",
      img: incicon2 },
    { name: "Bathroom cleaning",
      img: incicon19 },
    { name: "Vacuuming",
      img: incicon1 },
    { name: "Kitchen cleaning",
      img: incicon4 },
    { name: "Washing",
      img: incicon11 },
    { name: "Mopping",
      img: incicon8 },
    { name: "Fridge cleaning",
      img: incicon7 },
    { name: "Ironing",
      img: incicon2 },
    { name: "Tidying",
      img: incicon10 },
    { name: "Oven cleaning",
      img: incicon6 },
    { name: "Pantry tidying",
      img: incicon10 },
    { name: "Surfaces wiped",
      img: incicon10 },
    { name: "Bed making",
      img: incicon17 },
    { name: "And much more",
      img: incicon9 }
  ];

  return (
    
      <div className="container mx-auto ">
      <div className="bg-white">
          <h2 className="text-3xl sm:text-4xl font-bold text-center mb-2 sm:mb-5">What can be included?</h2>
          <p className="text-sm sm:text-base mb-4 sm:mb-12 text-center">
            Any household chore, including mopping, ironing or doing the washing. Each clean 
            is fully customisable to meet your needs. Just add any special requests in our 
            simple booking process.
          </p>
        <div className="bg-gray-50 rounded-lg p-4">
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-y-6 gap-x-5">
            {services.map((service, index) => (
              <div key={index} className="inline-block text-center shadow-md rounded-lg p-4 bg-yellow-50">
                <div className='w-24 h-24 p-3 bg-white shadow-lg mb-4 mx-auto rounded-full'>
                  <img src={service.img} className="w-full h-auto object-cover" />
                </div>
                <span className="text-lg lg:text-xl xl:text-2xl">{service.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;