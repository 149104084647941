import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";
import {
  FaSearch,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import logo from "../images/logo.png";
import flogo from "../images/askjayandjkproperty.png";
import aisearch from "../images/AISearch.png";
import callicon from "../images/CallIcon.png";
import smsicon from "../images/SMSicon.png";

const Footer = () => {
  const [postcode, setPostcode] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (postcode) {
      window.location.href = `https://askjay.trbdev.in/book/index.php?postcode=${postcode}`;
    }
  };

  return (
    <footer className="bg-gray-50">
      <div className="container mx-auto px-4">
        {/* Booking and Contact Section */}
        <div className="py-12 md:py-16 border-b border-gray-200">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
            <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
                Book your cleaner in 60 seconds.
              </h2>
              <p className="text-gray-600 mb-6">
                Using our simple booking form you can have your clean booked in
                60 seconds.
              </p>
              <form
                onSubmit={handleSubmit}
                className="flex items-center bg-white rounded-lg overflow-hidden shadow-md mb-6"
              >
                <div className="flex-grow flex items-center px-4">
                  <FaSearch className="text-gray-400 mr-2" />
                  <input
                    type="text"
                    placeholder="Enter your postcode"
                    className="w-full py-3 focus:outline-none"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-yellow-300 text-dark px-6 py-3 font-medium hover:bg-yellow-400 transition duration-300"
                >
                  Get Pricing & Book Online
                </button>
              </form>
            </div>
            <div className="w-full md:w-1/2 md:pl-8">
              <h2 className="text-2xl md:text-3xl font-bold mb-4">
                Or call our friendly team
              </h2>
              <p className="text-gray-600 mb-6">
                Get your questions answered with our customer service's team!
              </p>
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
                <a
                  href="tel:1300364646"
                  className="bg-white text-gray-800 font-semibold px-6 py-2 border border-gray-300 rounded-md hover:bg-gray-50 transition-colors mb-4 sm:mb-0 sm:mr-4"
                >
                  Call 1300 364 646
                </a>
                <span className="text-sm text-gray-500">
                  enquiries MON-FRI 8:30am - 5pm AEST
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="py-12">
          {/* Absolute Domestics Row */}
          <div className="grid grid-cols-4 mb-8 border-b pb-4">
            <div>
              <img src={flogo} alt="" className="w-full" />
              <h3 className="font-bold text-lg mt-4">
                ABN Number: 54642966701
              </h3>
            </div>
            <div className="pl-0 md:pl-8">
              <h3 className="font-bold text-lg mb-4">Ask Jay</h3>
              <h4 className="font-semibold mb-2">Head Office</h4>
              <p className="text-sm mb-2">⁠PO 2525, Figtree NSW</p>
              <h4 className="font-semibold mb-2">Phone</h4>
              <p className="text-sm mb-2">
                <Link to="tel:1300520360">1300 520 360</Link>
              </p>
              <h4 className="font-semibold mb-2">Email</h4>
              <p className="text-sm mb-2">
                <Link to="mailto:info@askjay.com.au">info@askjay.com.au</Link>
              </p>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">Operating Hours</h3>
              <p className="text-sm mb-4">
                7 days a Week
                <br />
                8:30 am - 5:00 pm
              </p>
              <h3 className="font-bold text-lg mb-4">Quick Links</h3>
              <ul className="text-sm space-y-2 mb-6">
                <li>
                  <a
                    href="https://askjay.trbdev.in/book/"
                    className="hover:text-gray-900"
                  >
                    Pricing & Bookings
                  </a>
                </li>
                <li>
                  <Link to="/locations" className="hover:text-gray-900">
                    Location
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="hover:text-gray-900">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/faqs" className="hover:text-gray-900">
                    FAQ's
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:text-gray-900">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">Services</h3>
              <ul className="text-sm space-y-2 mb-6">
                <li>
                  <Link to="/services/ndis" className="hover:text-gray-900">
                    NDIS cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/regular-clean"
                    className="hover:text-gray-900"
                  >
                    Regular Cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/deep-clean"
                    className="hover:text-gray-900"
                  >
                    Deep Cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/lease-clean"
                    className="hover:text-gray-900"
                  >
                    End of Lease cleaning
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/gardening"
                    className="hover:text-gray-900"
                  >
                    Gardening
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/pressure-wash"
                    className="hover:text-gray-900"
                  >
                    Pressure Wash
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/carpet-clean"
                    className="hover:text-gray-900"
                  >
                    Carpet Steam Cleaning
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Other Sections Row */}
          <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-8 gap-8">
            {/* New South Wales Column */}
            <div>
              <h3 className="font-bold text-lg mb-4">NSW</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sydney
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Newcastle
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Central Coast
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wollongong
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Maitland
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tweed Heads
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wagga Wagga
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Albury
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Macquarie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tamworth
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Orange
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dubbo
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Queanbeyan
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lismore
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bathurst
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Coffs Harbour
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Richmond
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Windsor
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bomaderry
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nowra
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Goulburn
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Armidale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Broken Hill
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wentworth Falls
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Katoomba
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Forster
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tuncurry
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bellbird
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Cessnock
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Grafton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Taree
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ballina
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Griffith
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sawtell
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Singleton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Raymond Terrace
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Weston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kurri Kurri
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kiama
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lithgow
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bowral
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Batemans Bay
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lawson
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hazelbrook
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Muswellbrook
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Parkes
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ulladulla
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Inverell
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Moree
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Casino
                  </Link>
                </li>
              </ul>
            </div>

            {/* Queensland and Victoria Column */}
            <div>
              <h3 className="font-bold text-lg mb-4">VIC</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Melbourne
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Geelong
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ballarat
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bendigo
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Shepparton-Mooroopna
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Melton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mildura
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wodonga
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Warrnambool
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sunbury
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Traralgon
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wangaratta
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Moe - Yallourn
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Morwell
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Horsham
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ocean Grove-Barwon Heads
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bacchus Marsh
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Pakenham
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Echuca
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bairnsdale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Warragul
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Colac
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Swan Hill
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Portland
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lara
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hamilton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Benalla
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Torquay
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Maryborough
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Clifton Springs
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Healesville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ararat
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Castlemaine
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Leopold
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Seymour
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wonthaggi
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Emerald
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Stawell
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Drouin
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kyabram
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lakes Entrance
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Churchill
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Cobram
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gisborne
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Leongatha
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kyneton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Yarrawonga
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wallan
                  </Link>
                </li>
              </ul>
            </div>

            {/* Other States Column */}
            <div>
              <h3 className="font-bold text-lg mb-4">QLD</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Brisbane
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gold Coast
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Townsville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Thuringowa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Cairns
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Toowoomba
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Rockhampton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mackay
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bundaberg
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hervey Bay
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gladstone
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Maryborough
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mount Isa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bongaree
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nambour
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Warwick
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Yeppoon
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gympie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Emerald
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dalby
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bowen
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Innisfail
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ayr
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Charters Towers
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kingaroy
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mareeba
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Moranbah
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Roma
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Atherton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Douglas
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Deeragun
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Goondiwindi
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Biloela
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bargara
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gatton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Blackwater
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ingham
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Jimboomba
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gracemere
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Beaudesert
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bellbowrie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Stanthorpe
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Airlie Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Cannonvale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Russell-Macleay Islands
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Longreach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tannum Sands
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Highfields
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gordonvale
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">SA</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Adelaide
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mount Gambier
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Whyalla
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gawler
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Pirie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bridgewater
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Augusta
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Murray Bridge
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Lincoln
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mount Barker
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Victor Harbor
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Aldinga Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Naracoorte
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Renmark
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Millicent
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Goolwa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Berri
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nuriootpa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tanunda
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kadina
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Roxby Downs
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Loxton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Strathalbyn
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Moonta
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Clare
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nairne
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wallaroo
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Coober Pedy
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    McLaren Vale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ceduna
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bordertown
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kapunda
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mannum
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Barmera
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Angaston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Willunga
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hahndorf
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Waikerie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kingscote
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lobethal
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Peterborough
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Woodside
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Elliot
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Balaklava
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sellicks Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kingston S.E.
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tailem Bend
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Jamestown
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Williamstown
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">WA</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Perth
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Rockingham
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mandurah
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bunbury
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kalgoorlie-Boulder
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Geraldton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Albany
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kwinana
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Broome
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Busselton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Hedland
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Karratha
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Esperance
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Carnarvon
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Collie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Northam
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Golden Bay-Singleton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kununurra
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Narrogin
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Manjimup
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Margaret River
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Derby
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Katanning
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Newman
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tom Price
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Exmouth
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kambalda West
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Merredin
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mundaring
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Harvey
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Denmark
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kalbarri
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Byford
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dongara
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bridgetown
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    York
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Yanchep
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Pinjarra
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mount Helena
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Waroona
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wickham
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Moora
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mount Barker
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dunsborough
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Donnybrook
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Stoneville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Parkerville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Two Rocks
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Fitzroy Crossing
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dampier
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">TAS</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hobart
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Launceston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Devonport
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Burnie
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Somerset
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Blackmans Bay
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kingston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ulverstone
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gagebrook
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bridgewater
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    New Norfolk
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Wynyard
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    George Town
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sorell - Midway Point
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Smithton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Penguin
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Longford
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Latrobe
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Dodges Ferry
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lauderdale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Queenstown
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Legana
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Deloraine
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Perth
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Old Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Sorell
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Scottsdale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hadspen
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    St Helens
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Stieglitz
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Huonville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ranelagh
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Pontville
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Bridport
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Turners Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Westbury
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Beauty Point
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Rosebery
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Seven Mile Beach
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Evandale
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Beaconsfield
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Sheffield
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Margate
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Zeehan
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Railton
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Geeveston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Richmond
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Cygnet
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Snug
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Currie
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">NT</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Darwin
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Alice Springs
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Palmerston
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Katherine
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    McMinns Lagoon
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nhulunbuy
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Howard Springs
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Tennant Creek
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Yulara
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Jabiru
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Maningrida
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Galiwinku
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Nguiu
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Port Keats (Wadeye)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Milingimbi
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Alyangula
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ngukurr
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Oenpelli
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Borroloola
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Angurugu
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Batchelor
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Numbulwar
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Lajamanu
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kintore
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gapuwiyak
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Yirrkala
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Daly River
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kalkarindji (Wave Hill)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Daguragu
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Ramingining
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Santa Teresa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mataranka
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Pine Creek
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hermannsburg
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Milikapiti (Snake Bay)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gagudju Cooinda Lodge
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Elliott
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Palumpa
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Umbakumba
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Pirlangimpi (Garden Point)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Beswick
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Alpurrurulam
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Willowra (Wirliyatjarrayi)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Barunga (Bamyili)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Warruwi
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Timber Creek
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Kaltukatjara (Docker River)
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Papunya
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Gunyangara
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Mandorah
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-lg mb-4">ACT</h3>
              <ul className="text-sm space-y-2">
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Canberra
                  </Link>
                </li>
                <li>
                  <Link to="#" className="hover:text-gray-900">
                    Hall
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Bottom Footer */}
        <div className="border-t border-gray-200 py-6 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <img src={logo} alt="" className="h-16 object-contain" />
          </div>
          <div className="flex space-x-4 mb-4 md:mb-0">
            <a
              href="https://www.facebook.com/profile.php?id=61566202946130"
              className="text-gray-400 hover:text-gray-600"
            >
              <FaFacebookF />
            </a>
            <a
              href="#https://www.instagram.com/askjaycompany/"
              className="text-gray-400 hover:text-gray-600"
            >
              <FaInstagram />
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-600">
              <FaLinkedinIn />
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-600">
              <FaYoutube />
            </a>
          </div>
          <div className="text-xs text-gray-500">
            © 2024 All Rights Reserved |{" "}
            <a href="https://www.maukaz.com/" className="text-blue-600">
              Developed By Maukaz Technology
            </a>
          </div>
        </div>
      </div>
      <a
        href="tel:1300520360"
        className="fixed bottom-10 right-12 w-16 z-[9999]"
      >
        <img src={callicon} alt="" />
      </a>
      <a href="#" className="fixed bottom-10 left-12 w-36 z-[9999]">
        <img src={aisearch} alt="" />
      </a>
      <a href="#" className="fixed bottom-28 right-12 w-16 z-[9999]">
        <img src={smsicon} alt="" />
      </a>
    </footer>
  );
};

export default Footer;
