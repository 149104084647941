import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Testimonial from '../components/Testimonial';
import servieimg1 from '../images/service1.jpg';
import { Row, Col } from "antd";
import breadBgImageabt  from '../images/slider5.jpeg';

const Locations = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Location" }
  ];
  const locations = [
    {
      "state": "New South Wales",
      "cities": [
        { "name": "Sydney", "url": "/nsw/sydney" },
        { "name": "Newcastle", "url": "/nsw/newcastle" },
        { "name": "Central Coast", "url": "/nsw/central-coast" },
        { "name": "Wollongong", "url": "/nsw/wollongong" },
        { "name": "Maitland", "url": "/nsw/maitland" },
        { "name": "Tweed Heads", "url": "/nsw/tweed-heads" },
        { "name": "Wagga Wagga", "url": "/nsw/wagga-wagga" },
        { "name": "Albury", "url": "/nsw/albury" },
        { "name": "Port Macquarie", "url": "/nsw/port-macquarie" },
        { "name": "Tamworth", "url": "/nsw/tamworth" },
        { "name": "Orange", "url": "/nsw/orange" },
        { "name": "Dubbo", "url": "/nsw/dubbo" },
        { "name": "Queanbeyan", "url": "/nsw/queanbeyan" },
        { "name": "Lismore", "url": "/nsw/lismore" },
        { "name": "Bathurst", "url": "/nsw/bathurst" },
        { "name": "Coffs Harbour", "url": "/nsw/coffs-harbour" },
        { "name": "Richmond", "url": "/nsw/richmond" },
        { "name": "Windsor", "url": "/nsw/windsor" },
        { "name": "Bomaderry", "url": "/nsw/bomaderry" },
        { "name": "Nowra", "url": "/nsw/nowra" },
        { "name": "Goulburn", "url": "/nsw/goulburn" },
        { "name": "Armidale", "url": "/nsw/armidale" },
        { "name": "Broken Hill", "url": "/nsw/broken-hill" },
        { "name": "Wentworth Falls", "url": "/nsw/wentworth-falls" },
        { "name": "Katoomba", "url": "/nsw/katoomba" },
        { "name": "Forster", "url": "/nsw/forster" },
        { "name": "Tuncurry", "url": "/nsw/tuncurry" },
        { "name": "Bellbird", "url": "/nsw/bellbird" },
        { "name": "Cessnock", "url": "/nsw/cessnock" },
        { "name": "Grafton", "url": "/nsw/grafton" },
        { "name": "Taree", "url": "/nsw/taree" },
        { "name": "Ballina", "url": "/nsw/ballina" },
        { "name": "Griffith", "url": "/nsw/griffith" },
        { "name": "Sawtell", "url": "/nsw/sawtell" },
        { "name": "Singleton", "url": "/nsw/singleton" },
        { "name": "Raymond Terrace", "url": "/nsw/raymond-terrace" },
        { "name": "Weston", "url": "/nsw/weston" },
        { "name": "Kurri Kurri", "url": "/nsw/kurri-kurri" },
        { "name": "Kiama", "url": "/nsw/kiama" },
        { "name": "Lithgow", "url": "/nsw/lithgow" },
        { "name": "Bowral", "url": "/nsw/bowral" },
        { "name": "Batemans Bay", "url": "/nsw/batemans-bay" },
        { "name": "Lawson", "url": "/nsw/lawson" },
        { "name": "Hazelbrook", "url": "/nsw/hazelbrook" },
        { "name": "Muswellbrook", "url": "/nsw/muswellbrook" },
        { "name": "Parkes", "url": "/nsw/parkes" },
        { "name": "Ulladulla", "url": "/nsw/ulladulla" },
        { "name": "Inverell", "url": "/nsw/inverell" },
        { "name": "Moree", "url": "/nsw/moree" },
        { "name": "Casino", "url": "/nsw/casino" }
      ]
    },    
    {
      "state": "Victoria",
      "cities": [
        { "name": "Melbourne", "url": "/vic/melbourne" },
        { "name": "Geelong", "url": "/vic/geelong" },
        { "name": "Ballarat", "url": "/vic/ballarat" },
        { "name": "Bendigo", "url": "/vic/bendigo" },
        { "name": "Shepparton-Mooroopna", "url": "/vic/shepparton-mooroopna" },
        { "name": "Melton", "url": "/vic/melton" },
        { "name": "Mildura", "url": "/vic/mildura" },
        { "name": "Wodonga", "url": "/vic/wodonga" },
        { "name": "Warrnambool", "url": "/vic/warrnambool" },
        { "name": "Sunbury", "url": "/vic/sunbury" },
        { "name": "Traralgon", "url": "/vic/traralgon" },
        { "name": "Wangaratta", "url": "/vic/wangaratta" },
        { "name": "Moe - Yallourn", "url": "/vic/moe-yallourn" },
        { "name": "Morwell", "url": "/vic/morwell" },
        { "name": "Horsham", "url": "/vic/horsham" },
        { "name": "Sale", "url": "/vic/sale" },
        { "name": "Ocean Grove-Barwon Heads", "url": "/vic/ocean-grove-barwon-heads" },
        { "name": "Bacchus Marsh", "url": "/vic/bacchus-marsh" },
        { "name": "Pakenham", "url": "/vic/pakenham" },
        { "name": "Echuca", "url": "/vic/echuca" },
        { "name": "Bairnsdale", "url": "/vic/bairnsdale" },
        { "name": "Warragul", "url": "/vic/warragul" },
        { "name": "Colac", "url": "/vic/colac" },
        { "name": "Swan Hill", "url": "/vic/swan-hill" },
        { "name": "Portland", "url": "/vic/portland" },
        { "name": "Lara", "url": "/vic/lara" },
        { "name": "Hamilton", "url": "/vic/hamilton" },
        { "name": "Benalla", "url": "/vic/benalla" },
        { "name": "Torquay", "url": "/vic/torquay" },
        { "name": "Maryborough", "url": "/vic/maryborough" },
        { "name": "Clifton Springs", "url": "/vic/clifton-springs" },
        { "name": "Healesville", "url": "/vic/healesville" },
        { "name": "Ararat", "url": "/vic/ararat" },
        { "name": "Castlemaine", "url": "/vic/castlemaine" },
        { "name": "Leopold", "url": "/vic/leopold" },
        { "name": "Seymour", "url": "/vic/seymour" },
        { "name": "Wonthaggi", "url": "/vic/wonthaggi" },
        { "name": "Emerald", "url": "/vic/emerald" },
        { "name": "Stawell", "url": "/vic/stawell" },
        { "name": "Drouin", "url": "/vic/drouin" },
        { "name": "Kyabram", "url": "/vic/kyabram" },
        { "name": "Lakes Entrance", "url": "/vic/lakes-entrance" },
        { "name": "Churchill", "url": "/vic/churchill" },
        { "name": "Cobram", "url": "/vic/cobram" },
        { "name": "Gisborne", "url": "/vic/gisborne" },
        { "name": "Leongatha", "url": "/vic/leongatha" },
        { "name": "Kyneton", "url": "/vic/kyneton" },
        { "name": "Yarrawonga", "url": "/vic/yarrawonga" },
        { "name": "Wallan", "url": "/vic/wallan" }
      ]
    },
    {
      "state": "Queensland",
      "cities": [
        { "name": "Brisbane", "url": "/qld/brisbane" },
        { "name": "Gold Coast", "url": "/qld/gold-coast" },
        { "name": "Townsville", "url": "/qld/townsville" },
        { "name": "Thuringowa", "url": "/qld/thuringowa" },
        { "name": "Cairns", "url": "/qld/cairns" },
        { "name": "Toowoomba", "url": "/qld/toowoomba" },
        { "name": "Rockhampton", "url": "/qld/rockhampton" },
        { "name": "Mackay", "url": "/qld/mackay" },
        { "name": "Bundaberg", "url": "/qld/bundaberg" },
        { "name": "Hervey Bay", "url": "/qld/hervey-bay" },
        { "name": "Gladstone", "url": "/qld/gladstone" },
        { "name": "Maryborough", "url": "/qld/maryborough" },
        { "name": "Mount Isa", "url": "/qld/mount-isa" },
        { "name": "Bongaree", "url": "/qld/bongaree" },
        { "name": "Nambour", "url": "/qld/nambour" },
        { "name": "Warwick", "url": "/qld/warwick" },
        { "name": "Yeppoon", "url": "/qld/yeppoon" },
        { "name": "Gympie", "url": "/qld/gympie" },
        { "name": "Emerald", "url": "/qld/emerald" },
        { "name": "Dalby", "url": "/qld/dalby" },
        { "name": "Bowen", "url": "/qld/bowen" },
        { "name": "Innisfail", "url": "/qld/innisfail" },
        { "name": "Ayr", "url": "/qld/ayr" },
        { "name": "Charters Towers", "url": "/qld/charters-towers" },
        { "name": "Kingaroy", "url": "/qld/kingaroy" },
        { "name": "Mareeba", "url": "/qld/mareeba" },
        { "name": "Moranbah", "url": "/qld/moranbah" },
        { "name": "Roma", "url": "/qld/roma" },
        { "name": "Atherton", "url": "/qld/atherton" },
        { "name": "Port Douglas", "url": "/qld/port-douglas" },
        { "name": "Deeragun", "url": "/qld/deeragun" },
        { "name": "Goondiwindi", "url": "/qld/goondiwindi" },
        { "name": "Biloela", "url": "/qld/biloela" },
        { "name": "Bargara", "url": "/qld/bargara" },
        { "name": "Gatton", "url": "/qld/gatton" },
        { "name": "Blackwater", "url": "/qld/blackwater" },
        { "name": "Ingham", "url": "/qld/ingham" },
        { "name": "Jimboomba", "url": "/qld/jimboomba" },
        { "name": "Gracemere", "url": "/qld/gracemere" },
        { "name": "Beaudesert", "url": "/qld/beaudesert" },
        { "name": "Bellbowrie", "url": "/qld/bellbowrie" },
        { "name": "Stanthorpe", "url": "/qld/stanthorpe" },
        { "name": "Airlie Beach", "url": "/qld/airlie-beach" },
        { "name": "Cannonvale", "url": "/qld/cannonvale" },
        { "name": "Russell-Macleay Islands", "url": "/qld/russell-macleay-islands" },
        { "name": "Longreach", "url": "/qld/longreach" },
        { "name": "Tannum Sands", "url": "/qld/tannum-sands" },
        { "name": "Highfields", "url": "/qld/highfields" },
        { "name": "Gordonvale", "url": "/qld/gordonvale" }
      ]
    },
    {
      "state": "South Australia",
      "cities": [
        { "name": "Adelaide", "url": "/sa/adelaide" },
        { "name": "Mount Gambier", "url": "/sa/mount-gambier" },
        { "name": "Whyalla", "url": "/sa/whyalla" },
        { "name": "Gawler", "url": "/sa/gawler" },
        { "name": "Port Pirie", "url": "/sa/port-pirie" },
        { "name": "Bridgewater", "url": "/sa/bridgewater" },
        { "name": "Port Augusta", "url": "/sa/port-augusta" },
        { "name": "Murray Bridge", "url": "/sa/murray-bridge" },
        { "name": "Port Lincoln", "url": "/sa/port-lincoln" },
        { "name": "Mount Barker", "url": "/sa/mount-barker" },
        { "name": "Victor Harbor", "url": "/sa/victor-harbor" },
        { "name": "Aldinga Beach", "url": "/sa/aldinga-beach" },
        { "name": "Naracoorte", "url": "/sa/naracoorte" },
        { "name": "Renmark", "url": "/sa/renmark" },
        { "name": "Millicent", "url": "/sa/millicent" },
        { "name": "Goolwa", "url": "/sa/goolwa" },
        { "name": "Berri", "url": "/sa/berri" },
        { "name": "Nuriootpa", "url": "/sa/nuriootpa" },
        { "name": "Tanunda", "url": "/sa/tanunda" },
        { "name": "Kadina", "url": "/sa/kadina" },
        { "name": "Roxby Downs", "url": "/sa/roxby-downs" },
        { "name": "Loxton", "url": "/sa/loxton" },
        { "name": "Strathalbyn", "url": "/sa/strathalbyn" },
        { "name": "Moonta", "url": "/sa/moonta" },
        { "name": "Clare", "url": "/sa/clare" },
        { "name": "Nairne", "url": "/sa/nairne" },
        { "name": "Wallaroo", "url": "/sa/wallaroo" },
        { "name": "Coober Pedy", "url": "/sa/coober-pedy" },
        { "name": "McLaren Vale", "url": "/sa/mclaren-vale" },
        { "name": "Ceduna", "url": "/sa/ceduna" },
        { "name": "Bordertown", "url": "/sa/bordertown" },
        { "name": "Kapunda", "url": "/sa/kapunda" },
        { "name": "Mannum", "url": "/sa/mannum" },
        { "name": "Barmera", "url": "/sa/barmera" },
        { "name": "Angaston", "url": "/sa/angaston" },
        { "name": "Willunga", "url": "/sa/willunga" },
        { "name": "Hahndorf", "url": "/sa/hahndorf" },
        { "name": "Waikerie", "url": "/sa/waikerie" },
        { "name": "Kingscote", "url": "/sa/kingscote" },
        { "name": "Lobethal", "url": "/sa/lobethal" },
        { "name": "Peterborough", "url": "/sa/peterborough" },
        { "name": "Woodside", "url": "/sa/woodside" },
        { "name": "Port Elliot", "url": "/sa/port-elliot" },
        { "name": "Balaklava", "url": "/sa/balaklava" },
        { "name": "Sellicks Beach", "url": "/sa/sellicks-beach" },
        { "name": "Kingston S.E.", "url": "/sa/kingston-se" },
        { "name": "Tailem Bend", "url": "/sa/tailem-bend" },
        { "name": "Jamestown", "url": "/sa/jamestown" },
        { "name": "Williamstown", "url": "/sa/williamstown" }
      ]
    },
    {
      "state": "Western Australia",
      "cities": [
        { "name": "Perth", "url": "/wa/perth" },
        { "name": "Rockingham", "url": "/wa/rockingham" },
        { "name": "Mandurah", "url": "/wa/mandurah" },
        { "name": "Bunbury", "url": "/wa/bunbury" },
        { "name": "Kalgoorlie-Boulder", "url": "/wa/kalgoorlie-boulder" },
        { "name": "Geraldton", "url": "/wa/geraldton" },
        { "name": "Albany", "url": "/wa/albany" },
        { "name": "Kwinana", "url": "/wa/kwinana" },
        { "name": "Broome", "url": "/wa/broome" },
        { "name": "Busselton", "url": "/wa/busselton" },
        { "name": "Port Hedland", "url": "/wa/port-hedland" },
        { "name": "Karratha", "url": "/wa/karratha" },
        { "name": "Esperance", "url": "/wa/esperance" },
        { "name": "Carnarvon", "url": "/wa/carnarvon" },
        { "name": "Collie", "url": "/wa/collie" },
        { "name": "Northam", "url": "/wa/northam" },
        { "name": "Golden Bay-Singleton", "url": "/wa/golden-bay-singleton" },
        { "name": "Kununurra", "url": "/wa/kununurra" },
        { "name": "Narrogin", "url": "/wa/narrogin" },
        { "name": "Manjimup", "url": "/wa/manjimup" },
        { "name": "Margaret River", "url": "/wa/margaret-river" },
        { "name": "Derby", "url": "/wa/derby" },
        { "name": "Katanning", "url": "/wa/katanning" },
        { "name": "Newman", "url": "/wa/newman" },
        { "name": "Tom Price", "url": "/wa/tom-price" },
        { "name": "Exmouth", "url": "/wa/exmouth" },
        { "name": "Kambalda West", "url": "/wa/kambalda-west" },
        { "name": "Merredin", "url": "/wa/merredin" },
        { "name": "Mundaring", "url": "/wa/mundaring" },
        { "name": "Harvey", "url": "/wa/harvey" },
        { "name": "Denmark", "url": "/wa/denmark" },
        { "name": "Kalbarri", "url": "/wa/kalbarri" },
        { "name": "Byford", "url": "/wa/byford" },
        { "name": "Dongara", "url": "/wa/dongara" },
        { "name": "Bridgetown", "url": "/wa/bridgetown" },
        { "name": "York", "url": "/wa/york" },
        { "name": "Yanchep", "url": "/wa/yanchep" },
        { "name": "Pinjarra", "url": "/wa/pinjarra" },
        { "name": "Mount Helena", "url": "/wa/mount-helena" },
        { "name": "Waroona", "url": "/wa/waroona" },
        { "name": "Wickham", "url": "/wa/wickham" },
        { "name": "Moora", "url": "/wa/moora" },
        { "name": "Mount Barker", "url": "/wa/mount-barker" },
        { "name": "Dunsborough", "url": "/wa/dunsborough" },
        { "name": "Donnybrook", "url": "/wa/donnybrook" },
        { "name": "Stoneville", "url": "/wa/stoneville" },
        { "name": "Parkerville", "url": "/wa/parkerville" },
        { "name": "Two Rocks", "url": "/wa/two-rocks" },
        { "name": "Fitzroy Crossing", "url": "/wa/fitzroy-crossing" },
        { "name": "Dampier", "url": "/wa/dampier" }
      ]
    },
    {
      "state": "Tasmania",
      "cities": [
        { "name": "Hobart", "url": "/tas/hobart" },
        { "name": "Launceston", "url": "/tas/launceston" },
        { "name": "Devonport", "url": "/tas/devonport" },
        { "name": "Burnie", "url": "/tas/burnie" },
        { "name": "Somerset", "url": "/tas/somerset" },
        { "name": "Blackmans Bay", "url": "/tas/blackmans-bay" },
        { "name": "Kingston", "url": "/tas/kingston" },
        { "name": "Ulverstone", "url": "/tas/ulverstone" },
        { "name": "Gagebrook", "url": "/tas/gagebrook" },
        { "name": "Bridgewater", "url": "/tas/bridgewater" },
        { "name": "New Norfolk", "url": "/tas/new-norfolk" },
        { "name": "Wynyard", "url": "/tas/wynyard" },
        { "name": "George Town", "url": "/tas/george-town" },
        { "name": "Sorell - Midway Point", "url": "/tas/sorell-midway-point" },
        { "name": "Smithton", "url": "/tas/smithton" },
        { "name": "Penguin", "url": "/tas/penguin" },
        { "name": "Longford", "url": "/tas/longford" },
        { "name": "Latrobe", "url": "/tas/latrobe" },
        { "name": "Dodges Ferry", "url": "/tas/dodges-ferry" },
        { "name": "Lauderdale", "url": "/tas/lauderdale" },
        { "name": "Queenstown", "url": "/tas/queenstown" },
        { "name": "Legana", "url": "/tas/legana" },
        { "name": "Deloraine", "url": "/tas/deloraine" },
        { "name": "Perth", "url": "/tas/perth" },
        { "name": "Old Beach", "url": "/tas/old-beach" },
        { "name": "Port Sorell", "url": "/tas/port-sorell" },
        { "name": "Scottsdale", "url": "/tas/scottsdale" },
        { "name": "Hadspen", "url": "/tas/hadspen" },
        { "name": "St Helens", "url": "/tas/st-helens" },
        { "name": "Stieglitz", "url": "/tas/stieglitz" },
        { "name": "Huonville", "url": "/tas/huonville" },
        { "name": "Ranelagh", "url": "/tas/ranelagh" },
        { "name": "Pontville", "url": "/tas/pontville" },
        { "name": "Bridport", "url": "/tas/bridport" },
        { "name": "Turners Beach", "url": "/tas/turners-beach" },
        { "name": "Westbury", "url": "/tas/westbury" },
        { "name": "Beauty Point", "url": "/tas/beauty-point" },
        { "name": "Rosebery", "url": "/tas/rosebery" },
        { "name": "Seven Mile Beach", "url": "/tas/seven-mile-beach" },
        { "name": "Evandale", "url": "/tas/evandale" },
        { "name": "Beaconsfield", "url": "/tas/beaconsfield" },
        { "name": "Sheffield", "url": "/tas/sheffield" },
        { "name": "Margate", "url": "/tas/margate" },
        { "name": "Zeehan", "url": "/tas/zeehan" },
        { "name": "Railton", "url": "/tas/railton" },
        { "name": "Geeveston", "url": "/tas/geeveston" },
        { "name": "Richmond", "url": "/tas/richmond" },
        { "name": "Cygnet", "url": "/tas/cygnet" },
        { "name": "Snug", "url": "/tas/snug" },
        { "name": "Currie", "url": "/tas/currie" }
      ]
    },
    {
      "state": "Northern Territory",
      "cities": [
        { "name": "Darwin", "url": "/nt/darwin" },
        { "name": "Alice Springs", "url": "/nt/alice-springs" },
        { "name": "Palmerston", "url": "/nt/palmerston" },
        { "name": "Katherine", "url": "/nt/katherine" },
        { "name": "McMinns Lagoon", "url": "/nt/mcminns-lagoon" },
        { "name": "Nhulunbuy", "url": "/nt/nhulunbuy" },
        { "name": "Howard Springs", "url": "/nt/howard-springs" },
        { "name": "Tennant Creek", "url": "/nt/tennant-creek" },
        { "name": "Yulara", "url": "/nt/yulara" },
        { "name": "Jabiru", "url": "/nt/jabiru" },
        { "name": "Maningrida", "url": "/nt/maningrida" },
        { "name": "Galiwinku", "url": "/nt/galiwinku" },
        { "name": "Nguiu", "url": "/nt/nguiu" },
        { "name": "Port Keats (Wadeye)", "url": "/nt/port-keats" },
        { "name": "Milingimbi", "url": "/nt/milingimbi" },
        { "name": "Alyangula", "url": "/nt/alyangula" },
        { "name": "Ngukurr", "url": "/nt/ngukurr" },
        { "name": "Oenpelli", "url": "/nt/oenpelli" },
        { "name": "Borroloola", "url": "/nt/borroloola" },
        { "name": "Angurugu", "url": "/nt/angurugu" },
        { "name": "Batchelor", "url": "/nt/batchelor" },
        { "name": "Numbulwar", "url": "/nt/numbulwar" },
        { "name": "Lajamanu", "url": "/nt/lajamanu" },
        { "name": "Kintore", "url": "/nt/kintore" },
        { "name": "Gapuwiyak", "url": "/nt/gapuwiyak" },
        { "name": "Yirrkala", "url": "/nt/yirrkala" },
        { "name": "Daly River", "url": "/nt/daly-river" },
        { "name": "Kalkarindji (Wave Hill)", "url": "/nt/kalkarindji" },
        { "name": "Daguragu", "url": "/nt/daguragu" },
        { "name": "Ramingining", "url": "/nt/ramingining" },
        { "name": "Santa Teresa", "url": "/nt/santa-teresa" },
        { "name": "Mataranka", "url": "/nt/mataranka" },
        { "name": "Pine Creek", "url": "/nt/pine-creek" },
        { "name": "Hermannsburg", "url": "/nt/hermannsburg" },
        { "name": "Milikapiti (Snake Bay)", "url": "/nt/milikapiti" },
        { "name": "Gagudju Cooinda Lodge", "url": "/nt/gagudju-cooinda-lodge" },
        { "name": "Elliott", "url": "/nt/elliott" },
        { "name": "Palumpa", "url": "/nt/palumpa" },
        { "name": "Umbakumba", "url": "/nt/umbakumba" },
        { "name": "Pirlangimpi (Garden Point)", "url": "/nt/pirlangimpi" },
        { "name": "Beswick", "url": "/nt/beswick" },
        { "name": "Alpurrurulam", "url": "/nt/alpurrurulam" },
        { "name": "Willowra (Wirliyatjarrayi)", "url": "/nt/willowra" },
        { "name": "Barunga (Bamyili)", "url": "/nt/barunga" },
        { "name": "Warruwi", "url": "/nt/warruwi" },
        { "name": "Timber Creek", "url": "/nt/timber-creek" },
        { "name": "Kaltukatjara (Docker River)", "url": "/nt/kaltukatjara" },
        { "name": "Papunya", "url": "/nt/papunya" },
        { "name": "Gunyangara", "url": "/nt/gunyangara" },
        { "name": "Mandorah", "url": "/nt/mandorah" }
      ]
    },
    {
      "state": "Australian Capital Territory",
      "cities": [
        { "name": "Canberra", "url": "/act/canberra" },
        { "name": "Hall", "url": "/act/hall" }
      ]
    },  
  ];
  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="Locations"
        description="Locations House Cleaning Services"
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImageabt}
      />
      <div className="py-12">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-6">Our Locations</h2>
          <Row
            gutter={[
              { xs: 8, sm: 16, md: 24, lg: 32 },
              { xs: 16, sm: 24, md: 32, lg: 48 },
            ]}
          >
            {locations.map((location) => (
              <Col
                key={location.state}
                xs={24}
                sm={12}
                md={8}
                lg={6}
              >
                <div className="bg-white p-6 rounded-lg shadow h-full">
                  <h3 className="text-xl font-semibold mb-4 text-gray-800">
                    {location.state}
                  </h3>
                  <ul className="space-y-2">
                    {location.cities.map((city) => (
                      <li key={city}>
                        <a
                          href={city.url}
                          className="text-base text-blue-600 hover:text-blue-800 transition-colors duration-200 ease-in-out"
                        >
                          {city.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img src={servieimg1} alt="" className="w-full h-auto rounded-lg md:pr-20 "/>
          </div>
          <div className="md:w-1/2 md:pl-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
              We help busy people with the housework all across Australia and New Zealand
            </h2>
            <p className="text-gray-600 mb-6">
              30+ Area Managers ready to take your call
            </p>
            <button className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">
              See our national team
            </button>
          </div>
        </div>
        </div>
      </div>
      <Testimonial />
    </>
  );
};

export default Locations;