import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Pricing from '../components/Pricing';
import servieimg1 from '../images/service1.jpg';
import { Row, Col } from "antd";
import Testimonial from '../components/Testimonial';
import KnowMore from '../components/KnowMore';
import breadBgImageabt from '../images/Service-Page-image_1.jpg';
import service1 from '../images/Service-Page-Square-image_1.jpg';
import service2 from '../images/Service-Page-Square-image_2.jpg';

const Services = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Services" }
  ];
  const steps = [
    {
      number: 1,
      title: "Book a cleaner online or over the phone",
      description:
        "Easily book a cleaning service online or over the phone. When you book your service, our Area Coordinators will find the perfect cleaner for your needs and allow selected Cleaning Contractors to view and request your job.",
    },
    {
      number: 2,
      title: "Your cleaner undergoes vetting procedures",
      description:
        "All Cleaners registered with the Agency undergo a rigorous vetting procedure to guarantee their suitability",
    },
    {
      number: 3,
      title: "We notify you that a cleaner has been selected",
      description:
        "When your cleaner has successfully passed our vetting procedures, you will be notified that we've found you the perfect match!",
    },
    {
      number: 4,
      title: "Meet your cleaner",
      description:
        "Your new cleaner will get in touch to setup a first meeting to introduce themselves. After your meeting, we'll contact you for a post-service follow up to ensure your satisfaction. If they don't meet your needs, rest assured we have an extensive network of alternative cleaners for you to choose from.",
    },
    {
      number: 5,
      title: "Put your feet up and relax",
      description:
        "Here's the fun part: relax and enjoy more free time and hassle-free home cleaning with us!",
    },
  ];
  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="Services"
        description="We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years."
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImageabt}
      />
      <div className='py-16'>
      <Pricing />
      </div>
      <div className="bg-[#fcf9f6]">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={service1} alt="" className="w-full h-auto rounded-lg md:pr-20 "/>
            </div>
            <div className="md:w-1/2 md:pl-6">
              <h5 className="mb-4 text-sm tracking-widest text-yellow-600">NDIS CLEANING SERVICES</h5>
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-4">
                Providers for NDIS, aged care and disability
              </h2>
              <p className="text-gray-600 mb-4">
                Our services stand out as dedicated providers for NDIS, aged
                care, and disability sectors, offering tailored home cleaning
                solutions. We ensure a comfortable, safe, and hygienic
                environment for all our clients, respecting their unique needs
                and preferences.
              </p>
              <a href="https://askjay.trbdev.in/book" className="inline-block bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">
                Book Online
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto px-4 py-16">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 md:pl-6">
              <h5 className="mb-4 text-sm tracking-widest text-yellow-600">About Us</h5>
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-4">
                Australia’s largest agency for domestic cleaners, trusted for
                over 30 years.
              </h2>
              <p className="text-gray-600 mb-4">
                Committed to excellence, we’ve spent over three decades offering
                reliable, high-quality domestic cleaning services. Our wide
                network in Australia and New Zealand means you can enjoy a
                spotless home, provided by professionals who care, no matter
                where you are.
              </p>
              <a href="https://askjay.trbdev.in/book" className="inline-block bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">
                Book Online
              </a>
            </div>
            <div className="md:w-1/2 mb-6 md:mb-0">
                <img src={service2} alt="" className="w-full h-auto rounded-lg md:pr-20 "/>
            </div>
          </div>
        </div>
      </div>
      <Testimonial/>
      <div className="bg-white  py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[32, 32]}>
            {/* Left side */}
            <Col xs={24} md={12}>
              <div className="sticky top-44">
                <h2 className="text-4xl font-bold mb-6">How it works?</h2>
                <p className="mb-6 text-lg">
                  We are an Agency for Domestic Cleaners that provides our
                  clients with trustworthy and reliable cleaners. Our
                  experienced area coordinators will ensure you have the right
                  cleaner that suits your needs.
                </p>
                <button className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">
                  Find your cleaner
              </button>
              </div>
            </Col>

            {/* Right side */}
            <Col xs={24} md={12}>
              {steps.map((step) => (
                <Row key={step.number} gutter={[16, 24]} className="mb-6">
                  <Col xs={24} sm={24} md={24} className="flex">
                    <div className="mr-3">
                      <span className="w-10 h-10 bg-green-500 text-white rounded-full flex items-center justify-center text-xl font-bold">
                        {step.number}
                      </span>
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </div>
      
    </>
  );
};

export default Services;