import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import serviceimg1 from '../images/Regularcleaning.png';
import serviceimg2 from '../images/Deepcleaning.png';
import serviceimg3 from '../images/endofleasecleaning.png';
import serviceimg4 from '../images/Gardening.png';
import serviceimg5 from '../images/NDIS.png';
import { Link } from 'react-router-dom';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const ServiceSlide = () => {
    const images = [
        { src: serviceimg5, alt: 'NDIS', link: '/services/ndis' },
        { src: serviceimg1, alt: 'Regular cleaning', link: '/services/regular-clean' },
        { src: serviceimg2, alt: 'Deep cleaning', link: '/services/deep-clean' },
        { src: serviceimg3, alt: 'end of lease cleaning', link: '/services/lease-clean' },
        { src: serviceimg4, alt: 'Gardening', link: '/services/gardening' },
        // Add more images as needed
      ];
  return (
    <div className="container mx-auto py-8 sm:py-16">
      <h2 className="text-3xl sm:text-4xl font-bold text-center mb-5 sm:mb-12">Cleaning services we offer</h2>
      <Swiper
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        navigation
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Link to={image.link}>
              <img src={image.src} alt={image.alt} className="w-full h-auto object-cover" />
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ServiceSlide;