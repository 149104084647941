import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';

const AlternateVideo = ({
  title,
  description,
  youtubeVideoId,
  imageUrl,
  imgPadding,
  isReversed = false,
  backgroundColor = 'bg-white'
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const contentOrder = isReversed ? 'md:order-last' : '';

  return (
    <section className={`py-16 ${backgroundColor}`}>
      <div className="container mx-auto px-4">
        <div className={`flex flex-col md:flex-row items-center ${isReversed ? 'md:flex-row-reverse' : ''}`}>
          <div className="w-full md:w-1/2 mb-8 md:mb-0">
            <div className={`relative ${imgPadding}`}>
              <img
                src={imageUrl}
                alt={title}
                className="w-full h-auto rounded-lg shadow-lg"
              />
              <button
                onClick={toggleModal}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-orange-500 rounded-full p-4 text-white hover:bg-orange-600 transition-colors"
              >
                <FaPlay size={24} />
              </button>
            </div>
          </div>
          <div className={`w-full md:w-1/2 md:px-8 ${contentOrder}`}>
            <h2 className="text-3xl font-bold mb-4">{title}</h2>
            <p className="text-gray-700 mb-4">{description}</p>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={toggleModal}>
          <div className="bg-white p-4 rounded-lg max-w-3xl w-full" onClick={(e) => e.stopPropagation()}>
            <div className="relative pt-[56.25%]">
              <iframe
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="absolute top-0 left-0 w-full h-full"
              ></iframe>
            </div>
            <button onClick={toggleModal} className="mt-4 bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600 transition-colors">
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default AlternateVideo;