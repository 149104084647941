import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Faqs from './pages/Faqs';
import Locations from './pages/Locations';
import Reviews from './pages/Reviews';
import Services from './pages/Services';
import Contact from './pages/Contact';
import ServiceDetails from './pages/services/ServiceDetails';
import LocationSingle from './pages/locations/LocationSingle';
import Jobs from './pages/Jobs';

// Import or define your services data
import servicesData from './data/servicesData';
import locationsData from './data/locationsData';

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/locations" element={<Locations />} />
          {locationsData.map(location => (
          <Route 
            key={location.id}
            path={`/locations/${location.slug}`} 
            element={<LocationSingle locationData={location} />} 
          />
        ))}
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          {servicesData.map(service => (
            <Route 
              key={service.id}
              path={`/services/${service.slug}`} 
              element={<ServiceDetails serviceData={service} />} 
            />
          ))}
          <Route path="/jobs" element={<Jobs />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;