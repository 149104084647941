import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Testimonial from '../components/Testimonial';
import breadBgImageabt from '../images/slider5.jpeg';
import service2 from '../images/Service-Page-Square-image_2.jpg';
import squreimg1 from "../images/squre1.jpg";
import squreimg2 from "../images/squre2.jpg";
import squreimg3 from "../images/squre3.jpg";
import squreimg4 from "../images/squre4.jpg";

const About = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "About" }
  ];
  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="About"
        description="We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years."
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImageabt}
      />
      <div className="bg-white  py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">Trusted by families.</h2>
              <p className="text-gray-600 mb-6">
                askJay has earned the trust of countless families by delivering reliable, thorough, and efficient cleaning services. Our professional cleaners take the time to understand your needs, ensuring your home is cleaned to perfection, creating a safe and healthy environment for your loved ones.
              </p>
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">Trusted by cleaners</h2>
              <p className='text-gray-600 mb-6'>
                We pride ourselves on supporting our cleaners, offering fair wages, flexibility, and the right tools to succeed. askJay cleaners are not only highly trained, but they also trust us to help them build long-term, rewarding careers.
              </p>
              <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium inline-block">
                  Book Online
              </a>
            </div>
            <div className="md:w-1/2 md:pl-6">
              <img src={squreimg1} alt="" className="w-full h-auto rounded-lg md:ml-20" />
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-6 md:mb-0">
              <img src={service2} alt="" className="w-full h-auto rounded-lg md:pr-20" />
            </div>
            <div className="md:w-1/2 md:pl-6">
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">Who is askJay?</h2>
              <p className="text-gray-600 mb-4">
                askJay is a professional cleaning service provider focused on making home cleaning effortless and stress-free. We connect busy families with trusted, vetted cleaners to ensure your home remains spotless, giving you more time to focus on what matters.
              </p>
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">What makes askJay different?</h2>
              <p className="text-gray-600 mb-4">
                At askJay, we prioritize customer satisfaction, offering a personalized cleaning experience and unmatched attention to detail. Our focus on quality, reliability, and convenience sets us apart from other cleaning agencies, ensuring you always come home to a clean, welcoming space.
              </p>
              <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium inline-block">
                  Book Online
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white  py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-6 md:mb-0">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
              Things you should know about askJay Cleaners
            </h2>
            <p className="text-gray-600 mb-6">
              Our cleaners go through a rigorous vetting process and receive ongoing training to ensure they deliver the highest quality service. They are reliable, trustworthy, and fully insured, giving you peace of mind every time you book a cleaning with askJay.
            </p>
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
              The truth about askJay customer service
            </h2>
            <p className='text-gray-600 mb-6'>
              Our customer service team is dedicated to making your experience as seamless as possible. From booking to feedback, we ensure that every interaction is handled with care, efficiency, and attention to detail, providing you with exceptional support.
            </p>
            <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium inline-block">
                  Book Online
            </a>
            </div>
            <div className="md:w-1/2 md:pl-6">
              <img src={squreimg2} alt="" className="w-full h-auto rounded-lg md:ml-20" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img src={squreimg3} alt="" className="w-full h-auto rounded-lg md:pr-20" />
          </div>
          <div className="md:w-1/2 md:pl-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
            Why choose a cleaning agency over a private cleaner
            </h2>
            <p className="text-gray-600 mb-6">
            Hiring a cleaning agency like askJay offers numerous advantages over private cleaners, including professional accountability, insurance coverage, and the reassurance that your cleaner has been thoroughly vetted. Plus, if your regular cleaner is unavailable, we ensure a replacement is always ready.
            </p>
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
            The importance of cleaning service satisfaction
            </h2>
            <p className='text-gray-600 mb-6'>
            We understand that a clean home is more than just aesthetics – it’s about comfort and well-being. That’s why askJay is committed to ensuring every client is satisfied with their cleaning service, offering flexible, high-quality solutions tailored to your lifestyle.
            </p>
            <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium inline-block">
                  Book Online
            </a>
          </div>
        </div>
        </div>
      </div>
      <div className="bg-white  py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="md:w-1/2 mb-6 md:mb-0">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
              The key features to look for in Domestic Cleaners in Australia
            </h2>
            <p className="text-gray-600 mb-6">
              When choosing a domestic cleaner in Australia, it’s important to look for reliability, trustworthiness, and experience. askJay ticks all these boxes, ensuring each cleaner is thoroughly vetted, insured, and trained to deliver top-tier services.
            </p>
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
              How Ask Jay got started
            </h2>
            <p className='text-gray-600 mb-6'>
            Founded on the belief that home cleaning should be simple and stress-free, Absolute Domestic was created to bridge the gap between busy families and professional cleaners. We’ve grown into a trusted name in the cleaning industry, delivering consistent, high-quality services across Australia.
            </p>
            <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium inline-block">
                  Book Online
            </a>
            </div>
            <div className="md:w-1/2 md:pl-6">
              <img src={squreimg4} alt="" className="w-full h-auto rounded-lg md:pl-20" />
            </div>
          </div>
        </div>
      </div>
      {/* <AlternateVideo 
        title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="YOUR_YOUTUBE_VIDEO_ID_1"
        isReversed={true}
        imageUrl={servieimg1}
        backgroundColor="bg-[#fcf9f6]"
        imgPadding="md:ml-20"
      />
      <AlternateVideo title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="O_hkVc3hLG4"
        imageUrl={servieimg1}
        imgPadding="md:mr-20"
        />
        <AlternateVideo title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="YOUR_YOUTUBE_VIDEO_ID_1"
        isReversed={true}
        imageUrl={servieimg1}
        backgroundColor="bg-[#fcf9f6]"
        imgPadding="md:ml-20"/>
      <AlternateVideo title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="O_hkVc3hLG4"
        imageUrl={servieimg1}
        imgPadding="md:mr-20"
        /> */}
    </>
  );
};

export default About;