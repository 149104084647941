import React from "react";
import Testimonial from "../components/Testimonial";
import servieimg1 from "../images/service1.jpg";
import AlternateVideo from "../components/AlternateVideo";
import breadBgImageabt from "../images/slider5.jpeg";
import WhyChoose from "../components/WhyChoose";

const Jobs = () => {
  return (
    <>
      <section
        class="relative bg-cover bg-left-top bg-no-repeat py-20 text-black"
        style={{ backgroundImage: `url(${breadBgImageabt})` }}
      >
        <div class="container mx-auto px-4 relative z-10">
          <nav class="flex mb-8" aria-label="Breadcrumb">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
              <li class="inline-flex items-center">
                <a
                  href="/"
                  class="inline-flex items-center text-sm font-medium"
                >
                  Home
                </a>
              </li>
              <li class="inline-flex items-center">
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="text-sm font-medium">Become a Cleaner</span>
              </li>
            </ol>
          </nav>
          <div class="max-w-2xl mt-20">
            <h1 class="text-5xl font-medium mb-4">Become a Cleaner</h1>
            <p class="text-md mb-12">
              Your page description goes here. This is a static version of the
              content you provided.
            </p>
            <div class="inline-block">
              <a
                href="https://askjay.trbdev.in/become-cleaner"
                class="bg-yellow-300 text-dark font-semibold text-lg p-4 rounded-lg mr-5"
              >
                Apply Now
              </a>
              <p class="text-sm text-black mt-2 inline-block">
                Enquiries MON - FRI 8:30 AM - 5:00 PM AEST
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="max-w-4xl mx-auto p-4">
        <h1 className="text-2xl font-bold text-center mb-6">
          Minimum Requirements
        </h1>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">You'll need a car:</h2>
          <p className="mb-4">
            Access to a personal vehicle is necessary for travel to the jobs.
            Public transport is only acceptable for candidates residing and
            seeking work in metropolitan cities where the trains, trams and
            buses are efficient. Additionally, those who intend to work just 3-4
            hours at a single job each day and do not need to travel to other
            jobs will be considered.
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Communication skills:</h2>
          <p className="mb-4">
            Good communication skills are desirable. This includes both reading
            and writing abilities.{" "}
            <strong>
              It's crucial you can understand and follow written and verbal
              instructions in English
            </strong>
            , especially when dealing with cleaning products and equipment.
          </p>
        </div>

        <h2 className="text-xl font-semibold mt-8 mb-4">Job suitability</h2>

        <div className="mb-4">
          <h3 className="font-semibold">Love cleaning:</h3>
          <p>
            This position is ideal for individuals who enjoy cleaning and giving
            a helping hand around the house to busy families who are time poor.
            If you consider yourself a cleaning champion, a neat nik, or a tidy
            enthusiast, and truly enjoy the process of cleaning, you will be a
            fantastic fit for the role.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold">Want flexible hours:</h3>
          <p>
            Working mums, students, semi retirees, and anyone who wants to run
            their own cleaning business and Be their Own Boss.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold">Want to keep their weekends:</h3>
          <p>
            People who don't want to work evenings or weekends, all work is
            during the week in daytime hours.
          </p>
        </div>

        <h2 className="text-xl font-semibold mt-8 mb-4">What to expect</h2>

        <div className="mb-4">
          <h3 className="font-semibold">A part of the family:</h3>
          <p>
            As a regular cleaner you'll be servicing the same homes week in and
            week out. Over time our clients will start to feel like you're a
            part of the family.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold">All equipment supplied:</h3>
          <p>
            Our regular clients supply all equipment and cleaning products, so
            you don't have cart your whole kit everywhere.
          </p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold">Full support:</h3>
          <p>
            Your dedicated area manager will be there to provide you with full
            support, helping to settle any issues with clients.
          </p>
        </div>
      </div>
      <WhyChoose />
      <Testimonial />
      <AlternateVideo
        title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="YOUR_YOUTUBE_VIDEO_ID_1"
        isReversed={true}
        imageUrl={servieimg1}
        backgroundColor="bg-[#fcf9f6]"
        imgPadding="md:ml-20"
      />
      <AlternateVideo
        title="Who is Absolute Domestics?"
        description="Meet the reliable professionals who've been keeping Australian homes spotless for over 30 years. This video gives you an inside look at Absolute Domestics – the efficient, trustworthy cleaning crew dedicated to making your life easier."
        youtubeVideoId="O_hkVc3hLG4"
        imageUrl={servieimg1}
        imgPadding="md:mr-20"
      />
    </>
  );
};

export default Jobs;
