import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({
  breadcrumbItems,
  title,
  description,
  phoneNumber,
  businessHours,
  backgroundImageSrc
}) => {
  return (
    <section 
      className="relative bg-cover bg-left-top bg-no-repeat py-20 text-black"
      style={{ backgroundImage: `url(${backgroundImageSrc})` }}
    >
      <div className="container mx-auto px-4 relative z-10">
        {/* Integrated Breadcrumb */}
        <nav className="flex mb-8" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            {breadcrumbItems.map((item, index) => (
              <li key={index} className="inline-flex items-center">
                {index > 0 && (
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                )}
                {item.href ? (
                  <Link to={item.href} className="inline-flex items-center text-sm font-medium">
                    {item.label}
                  </Link>
                ) : (
                  <span className="text-sm font-medium">{item.label}</span>
                )}
              </li>
            ))}
          </ol>
        </nav>
        
        {/* Main Content */}
        <div className="max-w-2xl mt-20">
          <h1 className="text-5xl font-medium mb-4">{title}</h1>
          <p className="text-md mb-12">{description}</p>
          <div className="inline-block">
            <a href={`tel:${phoneNumber}`} className="border font-semibold text-lg bg-white p-4 rounded-lg mr-5">
              Call {phoneNumber}
            </a>
            <p className="text-sm text-black mt-2 inline-block">{businessHours}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Breadcrumb;