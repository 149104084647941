import React from 'react';
import { Collapse } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const Accordion = ({ items }) => {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <Collapse
      onChange={onChange}
      expandIcon={({ isActive }) =>
        isActive ? <CaretUpOutlined /> : <CaretDownOutlined />
      }
      className="bg-white border-0"
    >
      {items.map((item, index) => (
        <Panel
          header={<p className='text-xl font-bold font-medium'>{item.title}</p>}
          key={index}
          className="rounded-md overflow-hidden mb-2 border"
        >
          <p className="text-gray-600 text-base font-medium">{item.content}</p>
        </Panel>
      ))}
    </Collapse>
  );
};

export default Accordion;