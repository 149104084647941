import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import ReviewSection from '../components/ReviewSection';
import breadBgImageabt from '../images/Review-Page-Page-image.jpg';

const Reviews = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Reviews" }
  ];
  const reviews = [
    {
      rating: 5,
      title: "Wonderful Service",
      content: "AskJay did a fantastic job cleaning my apartment! The team was professional, thorough, and respectful of my space. I highly recommend their services!",
      author: " Sarah M."
    },
    {
      rating: 4,
      title: "Excellent friendly service",
      content: "Great service overall! The team arrived on time and completed the deep cleaning efficiently. A few areas could have used more attention, but I'm satisfied with the results.",
      author: "Mike T."
    },
    {
      rating: 5,
      title: "Wonderful Service",
      content: "I’ve used AskJay multiple times, and they never disappoint. The staff is friendly, and they always leave my home sparkling clean. Worth every penny!",
      author: "Linda G."
    },
    {
      rating: 4,
      title: "",
      content: "Good experience with AskJay. The booking process was easy, and the cleaning was satisfactory. Would use them again for sure!",
      author: "Raj P."
    },
    {
      rating: 5,
      title: "",
      content: "AskJay transformed my office space! Their attention to detail is impressive, and they used eco-friendly products, which is a big plus for me. Highly recommend!",
      author: "Emily R."
    },
    {
      rating: 5,
      title: "",
      content: "Absolutely love AskJay! They cleaned my house before a big family gathering, and everything looked perfect. Very reliable and efficient!",
      author: "John D."
    },
    {
      rating: 4,
      title: "",
      content: "The cleaning team was friendly and did a solid job. A couple of missed spots, but they quickly fixed them upon request. Overall, a positive experience!",
      author: "Priya S."
    },
    {
      rating: 5,
      title: "",
      content: "AskJay is my go-to for cleaning services. They are punctual, professional, and always do an outstanding job. Five stars from me!",
      author: "Tom L."
    },
    {
      rating: 4,
      title: "",
      content: "The team at AskJay did a great job cleaning my carpets. They looked brand new afterward! A bit pricey, but the quality of service makes it worthwhile.",
      author: "Nisha K."
    },
    {
      rating: 5,
      title: "",
      content: "Fantastic service! The team was thorough and left no corner untouched. I'm extremely happy with how my home turned out. Definitely using them again!",
      author: "Jake S."
    },
    {
      rating: 4,
      title: "",
      content: "Had a great experience with AskJay for my move-out cleaning. They were prompt and completed everything on time. A few things could have been better, but overall, a job well done!",
      author: "Laura H."
    },
    {
      rating: 5,
      title: "",
      content: "I’ve tried various cleaning companies, but AskJay is by far the best. They are detail-oriented and professional. I highly recommend their services!",
      author: "Steven W."
    },
    {
      rating: 4,
      title: "",
      content: "AskJay provided a thorough cleaning service. They are a bit more expensive than others, but the quality of their work justifies the cost",
      author: "Riya J."
    },
    {
      rating: 5,
      title: "",
      content: "Super impressed with AskJay! They cleaned my home in record time without compromising quality. Very happy with the results!",
      author: "David P."
    },
    {
      rating: 4,
      title: "",
      content: "Overall, a positive experience with AskJay. The team was friendly and efficient, though a couple of areas needed a bit more attention. I would recommend them to others!",
      author: "Aisha N."
    },
    // Add more reviews as needed
  ];
  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="Reviews"
        description="We are Australia's largest agency for domestic cleaners, trusted by thousands of families for over 30 years."
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImageabt}
      />
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:pr-6">
              Over 3,400+ glowing reviews!
            </h2>
            <p className="text-gray-600 text-sm md:text-base lg:text-lg mb-6">
              With over 3,400 four and five-star reviews, our reputation as
              domestic cleaners in Australia is important to us, as is the
              safety and security of you, your family and your home. We recruit,
              train and manage only the bestcleaners for your home
            </p>
          </div>
          {/* Review Cards */}
          <ReviewSection reviews={reviews} />
        </div>
      </div>
    </>
  );
};

export default Reviews;