import React from 'react';
import Banner from '../components/Banner';
import Pricing from '../components/Pricing';
import WhyChoose from '../components/WhyChoose';
import Testimonial from '../components/Testimonial';
import ServiceSlide from '../components/ServiceSlide';

const Home = () => {
  return (
    <>
      <Banner />
      <ServiceSlide/>
      <div className='pb-8 sm:pb-16'>
      <Pricing/>
      </div>
      <Testimonial/>
      <WhyChoose/>
    </>
  );
};

export default Home;