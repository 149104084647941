import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import whyicon1 from '../images/icons/12.png';
import whyicon2 from '../images/icons/17.png';
import whyicon3 from '../images/icons/13.png';
import whyicon4 from '../images/icons/14.png';
import whyicon5 from '../images/icons/16.png';
import whyicon6 from '../images/icons/18.png';

const WhyChoose = () => {
  const reasons = [
    { text: "Over 10 years in the industry",
      img: whyicon1
     },
    { text: "Local customer service team",
      img: whyicon2
     },
    { text: "Satisfaction guarantee",
      img: whyicon3
     },
    { text: "Police-checked cleaners",
      img: whyicon4
     },
    { text: "Friendly and trustworthy",
      img: whyicon5
     },
    {
      text: "Public Liability Insurance and Accident Insurance for Contractors",
      img: whyicon6
    },
  ];

  return (
    <section className="py-12 md:py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-2 sm:mb-5">
          Why Choose AskJay?
        </h2>
        <p className="text-sm sm:text-base mb-4 sm:mb-6 text-center">
          With years of expertise in domestic cleaning, we understand your
          household needs. Whether you need a one-time cleaning or full
          housekeeping services, we’ve got you covered. Select only the specific
          services you require or opt for our regular care. Our trusted team
          ensures your home is spotless and well-maintained. At AskJay, we
          prioritise giving you back precious time. Focus on what matters most,
          while we handle the housework. Enjoy a cleaner home and a balanced
          lifestyle, effortlessly.
        </p>

        {/* Right side list */}
          <ul className="grid grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-5">
            {reasons.map((reason, index) => (
              <li key={index} className="flex items-center p-4 bg-yellow-50">
                  <img src={reason.img} className="w-24 h-24 bg-white shadow-lg rounded-full p-3 mr-3" />
                <span className="text-lg lg:text-xl">{reason.text}</span>
              </li>
            ))}
          </ul>
      </div>
    </section>
  );
};

export default WhyChoose;
