import servieimg1 from "../images/Service-Page-Square-image_1.jpg";
import servieimg2 from "../images/Service-Page-Square-image_2.jpg";
import ndisBanner from "../images/NDIS-cleaning.jpg";
import regularBanner from "../images/regular-cleaning.jpg";
import deepClean from "../images/Deep-cleaning.jpg";
import leaseClean from "../images/End-of-Lease-cleaning-cleaning.jpg";
import gardenBanner from "../images/Gardening.jpg";
import pressureBanner from "../images/Pressure-Wash.jpg";
import carpetBanner from "../images/Carpet-Steam-Cleaning.jpg";
import squreimg1 from "../images/squre1.jpg";
import squreimg2 from "../images/squre2.jpg";
import squreimg3 from "../images/squre3.jpg";
import squreimg4 from "../images/squre4.jpg";
import incicon1 from '../images/dust.png';
import incicon2 from '../images/clean-linen-basket.png';
// Import other service images as needed

const servicesData = [
  {
    id: 1,
    slug: "ndis",
    servbreadBgImage: ndisBanner,
    breadTitle: "NDIS Cleaning Services",
    breadDescription:
      "Everyone's needs a little help keeping things clean, neat and tidy, and professional NDIS cleaning services go above and beyond regular services to provide those in need with the support they deserve.",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: squreimg1,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting",
        img: incicon1
       },
      { name: "Linen changing",
        img: incicon2 },
      { name: "Bathroom cleaning",
        img: incicon1 },
      { name: "Vacuuming",
        img: incicon1 },
      { name: "Kitchen cleaning",
        img: incicon1 },
      { name: "Washing",
        img: incicon1 },
      { name: "Mopping",
        img: incicon1 },
      { name: "Fridge cleaning",
        img: incicon1 },
      { name: "Ironing",
        img: incicon1 },
      { name: "Tidying",
        img: incicon1 },
      { name: "Oven cleaning",
        img: incicon1 },
      { name: "Pantry tidying",
        img: incicon1 },
      { name: "Surfaces wiped",
        img: incicon1 },
      { name: "Bed making",
        img: incicon1 },
      { name: "And much more",
        img: incicon1 }
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "NDIS Cleaning Services Sydney",
      "NDIS Cleaning Services Melbourne",
      "NDIS Cleaning Services Brisbane",
      "NDIS Cleaning Services Newcastle",
      "NDIS Cleaning Services Adelaide",
      "NDIS Cleaning Services Toowoomba",
      "NDIS Cleaning Services Wollongong",
      "NDIS Cleaning Services Western Sydney",
      "NDIS Cleaning Services Perth",
      "NDIS Cleaning Services Campbelltown",
      "NDIS Cleaning Services Geelong",
      "NDIS Cleaning Services Gold Coast",
      "NDIS Cleaning Services Central Coast",
      "NDIS Cleaning Services Surry Hills",
      "NDIS Cleaning Services Sunshine Coast"
    ]
  },
  {
    id: 2,
    slug: "regular-clean",
    servbreadBgImage: regularBanner,
    breadTitle: "Regular Cleaning Services",
    breadDescription:
      "At askJay, we provide comprehensive regular cleaning services designed to keep your home or office spotless and organized. Our skilled professionals use eco-friendly products and advanced techniques to ensure every corner is cleaned to perfection. Whether it's daily, weekly, or customized cleaning plans, we ensure a consistently clean environment, giving you more time to focus on what matters most. Let us take care of your cleaning needs efficiently and carefully.",
    aboutTitle: "Why Choose askJay for Your Regular Cleaning Needs?",
    aboutContent: [`
      At askJay, we understand that maintaining a clean and healthy environment is essential for your well-being and productivity. Our expert cleaning team is trained to deliver thorough and consistent results, ensuring that your home or business always looks its best. We use eco-friendly cleaning products and cutting-edge equipment, prioritizing safety and efficiency in every task. With flexible scheduling options and personalized service plans, we cater to your unique requirements, so you can enjoy a spotless space without disrupting your routine.
    `],
    aboutImage: squreimg3,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" }
      // ... Add other services here
    ],
    howstartContent: "Trained and Experienced Team: Our cleaning professionals are highly trained and bring years of experience in handling diverse cleaning challenges. We guarantee meticulous attention to detail for spotless results.",
    steps: [
      {
        number: 1,
        title: "Eco-Friendly Products:",
        description: `We use safe, eco-friendly cleaning products to protect your home, family, and the environment, ensuring a healthier living space.`,
      },
      {
        number: 2,
        title: "Customized Cleaning Plans:",
        description: `Every home is different. We tailor our cleaning services to fit your specific needs and preferences, ensuring a personalized experience.`,
      },
      {
        number: 3,
        title: "Reliable and Punctual:",
        description: `We value your time. Our team arrives on schedule and completes the job efficiently without compromising on quality.`,
      },
      {
        number: 4,
        title: "Affordable Pricing:",
        description: `Enjoy premium cleaning services without breaking the bank. We offer transparent, competitive pricing with no hidden costs.`,
      },
      {
        number: 5,
        title: "Customer Satisfaction Guarantee:",
        description: `Your satisfaction is our priority. If you’re not happy with any aspect of our service, we’ll make it right with a prompt follow-up.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "Regular Cleaning Services Sydney",
      "Regular Cleaning Services Melbourne",
      "Regular Cleaning Services Brisbane",
      "Regular Cleaning Services Newcastle",
      "Regular Cleaning Services Adelaide",
      "Regular Cleaning Services Toowoomba",
      "Regular Cleaning Services Wollongong",
      "Regular Cleaning Services Western Sydney",
      "Regular Cleaning Services Perth",
      "Regular Cleaning Services Campbelltown",
      "Regular Cleaning Services Geelong",
      "Regular Cleaning Services Gold Coast",
      "Regular Cleaning Services Central Coast",
      "Regular Cleaning Services Surry Hills",
      "Regular Cleaning Services Sunshine Coast"
    ],    
  },
  {
    id: 3,
    slug: "deep-clean",
    servbreadBgImage: deepClean,
    breadTitle: "Deep Cleaning Services",
    breadDescription:
      "At askJay, we go beyond the surface to provide a thorough, top-to-bottom deep cleaning for your home or office. Our expert team uses advanced techniques and eco-friendly products to reach every corner, ensuring a spotless and hygienic environment. From kitchens and bathrooms to carpets and windows, we make sure every area is meticulously cleaned, leaving your space refreshed and germ-free. Trust askJay for a deeper clean that transforms your space.",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: squreimg2,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" },
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "Deep Cleaning Services Sydney",
      "Deep Cleaning Services Melbourne",
      "Deep Cleaning Services Brisbane",
      "Deep Cleaning Services Newcastle",
      "Deep Cleaning Services Adelaide",
      "Deep Cleaning Services Toowoomba",
      "Deep Cleaning Services Wollongong",
      "Deep Cleaning Services Western Sydney",
      "Deep Cleaning Services Perth",
      "Deep Cleaning Services Campbelltown",
      "Deep Cleaning Services Geelong",
      "Deep Cleaning Services Gold Coast",
      "Deep Cleaning Services Central Coast",
      "Deep Cleaning Services Surry Hills",
      "Deep Cleaning Services Sunshine Coast"
    ],    
  },
  {
    id: 4,
    slug: "lease-clean",
    servbreadBgImage: leaseClean,
    breadTitle: "End of Lease Cleaning Services",
    breadDescription:
      "Moving out? Let us handle the cleaning! At askJay, we specialize in thorough, top-to-bottom cleaning to ensure your rental property is spotless and ready for inspection. Our team uses professional-grade equipment to deep clean carpets, kitchens, bathrooms, and more, helping you meet your lease requirements and get your deposit back hassle-free. Trust askJay for a seamless move-out experience.",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: servieimg2,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" },
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "End of Lease Cleaning Services Sydney",
      "End of Lease Cleaning Services Melbourne",
      "End of Lease Cleaning Services Brisbane",
      "End of Lease Cleaning Services Newcastle",
      "End of Lease Cleaning Services Adelaide",
      "End of Lease Cleaning Services Toowoomba",
      "End of Lease Cleaning Services Wollongong",
      "End of Lease Cleaning Services Western Sydney",
      "End of Lease Cleaning Services Perth",
      "End of Lease Cleaning Services Campbelltown",
      "End of Lease Cleaning Services Geelong",
      "End of Lease Cleaning Services Gold Coast",
      "End of Lease Cleaning Services Central Coast",
      "End of Lease Cleaning Services Surry Hills",
      "End of Lease Cleaning Services Sunshine Coast"
    ],    
  },
  {
    id: 5,
    slug: "gardening",
    servbreadBgImage: gardenBanner,
    breadTitle: "Gaedening Services",
    breadDescription:
      "Transform your outdoor space with our expert gardening services. From routine maintenance to full garden makeovers, askJay provides professional care to ensure your garden flourishes year-round. Whether it’s pruning, lawn care, planting, or landscape design, our team is dedicated to keeping your garden vibrant and healthy. Let us help you create a beautiful and sustainable green space that enhances your home.",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: servieimg1,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" },
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "Gardening Services Sydney",
      "Gardening Services Melbourne",
      "Gardening Services Brisbane",
      "Gardening Services Newcastle",
      "Gardening Services Adelaide",
      "Gardening Services Toowoomba",
      "Gardening Services Wollongong",
      "Gardening Services Western Sydney",
      "Gardening Services Perth",
      "Gardening Services Campbelltown",
      "Gardening Services Geelong",
      "Gardening Services Gold Coast",
      "Gardening Services Central Coast",
      "Gardening Services Surry Hills",
      "Gardening Services Sunshine Coast"
    ],    
  },
  {
    id: 6,
    slug: "pressure-wash",
    servbreadBgImage: pressureBanner,
    breadTitle: "Pressure wash Services",
    breadDescription:
      "At askJay, we provide professional pressure washing services that make your exteriors look like new. From driveways and patios to walls and roofs, our high-pressure cleaning solutions effectively remove dirt, grime, mold, and stains, restoring the appearance of your property. With eco-friendly methods and skilled technicians, we ensure a spotless, long-lasting finish every time. Let us help you maintain a clean and inviting outdoor space!",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: servieimg2,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" },
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "NDIS Cleaning Services Sydney",
      "NDIS Cleaning Services Melbourne",
      // ... Add other service areas here
    ],
  },
  {
    id: 7,
    slug: "carpet-clean",
    servbreadBgImage: carpetBanner,
    breadTitle: "Carpet Cleaning Services",
    breadDescription:
      "At askJay, we understand that clean carpets contribute to a healthy and inviting home. Our professional carpet cleaning services utilize advanced techniques and eco-friendly products to remove dirt, stains, and allergens from your carpets. With our expert team, we ensure a thorough deep clean that revitalizes your carpets, leaving them fresh and looking like new. Whether it’s a routine cleaning or a special event preparation, trust us to provide exceptional results and a healthier living environment for you and your family. Experience the difference with askJay—your trusted partner in home cleaning solutions!",
    aboutTitle: "What NDIS means to us?",
    aboutContent: [`
      We understand the toll a chaotic household can have on your well-being and that not everyone 
      has circumstances allowing them to tackle this issue themselves. That's why our professional 
      cleaners are determined to give NDIS participants the cleaning services they deserve.
      A clean home means a happier household and more control over our life's direction. We work 
      directly with our client or their plan manager to improve their lives through an extensive 
      range of cleaning services.
      This can be through weekly or fortnightly cleans. No matter the situation, Absolute Domestics 
      is here to help you and your needs.
    `],
    aboutImage: squreimg4,
    hireContent:
      "Here at Absolute Domestics, we are approved providers of the National Disability Insurance Scheme (NDIS), aiming to support Australian families to make their houses a home through maintenance services.",
    includeContent:
      "Any household chore, including mopping, ironing or doing the washing. Each clean is fully customisable to meet your needs. Just add any special requests in our simple booking process.",
    benefits: [
      {
        title: "30+ Area Coordinators",
        description:
          "Our vast network of Area Coordinators are ready to take your call.",
      },
      {
        title: "Over 30 years experience",
        description:
          "With 30+ years of cleaning industry experience, we deliver exceptional service.",
      },
      {
        title: "Local customer service team",
        description:
          "Our local customer service team offers personalised support.",
      },
      {
        title: "Satisfaction guarantee",
        description:
          "We provide a satisfaction guarantee for complete peace of mind.",
      },
      {
        title: "Police-checked cleaners",
        description:
          "Friendly and trustworthy, all our cleaners are police-checked for your assurance and safety.",
      },
      {
        title: "Insurance",
        description:
          "$20M Public Liability Cover and our cleaners have accident coverage for your peace of mind.",
      },
      // ... Add other benefits here
    ],
    services: [
      { name: "Dusting" },
      { name: "Linen changing" },
      { name: "Bathroom cleaning" },
      { name: "Vacuuming" },
      { name: "Kitchen cleaning" },
      { name: "Washing" },
      { name: "Mopping" },
      { name: "Fridge cleaning" },
      { name: "Ironing" },
      { name: "Tidying" },
      { name: "Oven cleaning" },
      { name: "Pantry tidying" },
      { name: "Surfaces wiped" },
      { name: "Bed making" },
      { name: "And much more" },
      // ... Add other services here
    ],
    howstartContent: "We are an Agency for Domestic Cleaners that provides our clients with trustworthy and reliable cleaners. Our experienced area coordinators will ensure you have the right cleaner that suits your needs.",
    steps: [
      {
        number: 1,
        title: "Book a cleaner online or over the phone",
        description: `Choose which service best suits your needs and book online in minutes. Wait for your area 
        coordinator to assign the perfect cleaner for you and give you a confirmation of services.`,
      },
      // ... Add other steps here
    ],
    serviceAreas: [
      "Pressure Wash Services Sydney",
      "Pressure Wash Services Melbourne",
      "Pressure Wash Services Brisbane",
      "Pressure Wash Services Newcastle",
      "Pressure Wash Services Adelaide",
      "Pressure Wash Services Toowoomba",
      "Pressure Wash Services Wollongong",
      "Pressure Wash Services Western Sydney",
      "Pressure Wash Services Perth",
      "Pressure Wash Services Campbelltown",
      "Pressure Wash Services Geelong",
      "Pressure Wash Services Gold Coast",
      "Pressure Wash Services Central Coast",
      "Pressure Wash Services Surry Hills",
      "Pressure Wash Services Sunshine Coast"
    ],    
  },



  // Add more services here following the same structure
];

export default servicesData;
