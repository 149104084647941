import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { FaStar } from 'react-icons/fa';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const testimonials = [
  {
    rating: 5,
    title: "Wonderful Service",
    content: "AskJay did a fantastic job cleaning my apartment! The team was professional, thorough, and respectful of my space. I highly recommend their services!",
    author: " Sarah M."
  },
  {
    rating: 4,
    title: "Excellent friendly service",
    content: "Great service overall! The team arrived on time and completed the deep cleaning efficiently. A few areas could have used more attention, but I'm satisfied with the results.",
    author: "Mike T."
  },
  {
    rating: 5,
    title: "Wonderful Service",
    content: "I’ve used AskJay multiple times, and they never disappoint. The staff is friendly, and they always leave my home sparkling clean. Worth every penny!",
    author: "Linda G."
  },
  {
    rating: 4,
    title: "",
    content: "Good experience with AskJay. The booking process was easy, and the cleaning was satisfactory. Would use them again for sure!",
    author: "Raj P."
  },
  {
    rating: 5,
    title: "",
    content: "AskJay transformed my office space! Their attention to detail is impressive, and they used eco-friendly products, which is a big plus for me. Highly recommend!",
    author: "Emily R."
  },
  {
    rating: 5,
    title: "",
    content: "Absolutely love AskJay! They cleaned my house before a big family gathering, and everything looked perfect. Very reliable and efficient!",
    author: "John D."
  },
  {
    rating: 4,
    title: "",
    content: "The cleaning team was friendly and did a solid job. A couple of missed spots, but they quickly fixed them upon request. Overall, a positive experience!",
    author: "Priya S."
  },
  {
    rating: 5,
    title: "",
    content: "AskJay is my go-to for cleaning services. They are punctual, professional, and always do an outstanding job. Five stars from me!",
    author: "Tom L."
  },
  {
    rating: 4,
    title: "",
    content: "The team at AskJay did a great job cleaning my carpets. They looked brand new afterward! A bit pricey, but the quality of service makes it worthwhile.",
    author: "Nisha K."
  },
  // Add more testimonials as needed
];

const TestimonialCard = ({ testimonial }) => (
  <div className="bg-yellow-50 p-6 rounded-lg shadow-xl transition-shadow duration-300 ease-in-out h-full">
    <div className="flex text-orange-500 mb-2">
      {[...Array(5)].map((_, i) => (
        <FaStar key={i} className={i < testimonial.rating ? 'text-yellow-300' : 'text-gray-300'} />
      ))}
    </div>
    <h3 className="text-xl font-semibold mb-2">{testimonial.title}</h3>
    <p className="text-gray-600 mb-4">{testimonial.content}</p>
    <p className="font-medium">{testimonial.author}</p>
  </div>
);

const Testimonial = () => {
  const handleSlideChange = () => {
    console.log('slide change');
  };

  const handleSwiper = (swiper) => {
    console.log(swiper);
  };

  return (
    <section className="py-12 md:py-16 bg-gray-50">
      <div className="container mx-auto">
        <h2 className="text-3xl sm:text-4xl font-bold text-center mb-2 sm:mb-5">What our clients are saying</h2>
        <p className="text-sm sm:text-base mb-4 sm:mb-12 text-center">
            With over 3,400+ 4 and 5 reviews and new testimonials added every week, 
            our reputation as domestic cleaners in Australia is important to us, as is 
            the safety and security of you, your family and your home.
          </p>
        <div className="relative px-0">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            navigation={{
              prevEl: '.swiper-button-prev',
              nextEl: '.swiper-button-next',
            }}
            pagination={{ clickable: true }}
            autoplay={false}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
            onSlideChange={handleSlideChange}
            onSwiper={handleSwiper}
            className="mySwiper !px-4"
          >
            {testimonials.map((testimonial, index) => (
              <SwiperSlide key={index} className="pb-12">
                <TestimonialCard testimonial={testimonial} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-button-prev !text-gray-800 !-left-4 bg-yellow-400"></div>
          <div className="swiper-button-next !text-gray-800 !-right-4"></div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;