import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { FaBars, FaTimes, FaUser } from 'react-icons/fa';
import logo from '../images/logo-2.png';
import { FaPhoneVolume, FaEnvelope } from 'react-icons/fa6';

const Header = () => {
  const location = useLocation();
  const [current, setCurrent] = useState('home');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.split('/')[1];
    setCurrent(path || 'home');
  }, [location]);

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  const items = [
    {
      label: <Link to="/">Home</Link>,
      key: 'home',
    },
    {
      label: <Link to="/services">Services</Link>,
      key: 'services',
      children: [
        {
          label: <Link to="/services/ndis">NDIS cleaning</Link>,
          key: 'regular',
        },
        {
          label: <Link to="/services/regular-clean">Regular Cleaning</Link>,
          key: 'oneof',
        },
        {
          label: <Link to="/services/deep-clean">Deep Cleaning</Link>,
          key: 'agecare',
        },
        {
          label: <Link to="/services/lease-clean">End of Lease cleaning</Link>,
          key: 'elsclean',
        },
        {
          label: <Link to="/services/gardening">Gardening</Link>,
          key: 'gardening',
        },
        {
          label: <Link to="/services/pressure-wash">Pressure Wash</Link>,
          key: 'pressurewash',
        },
        {
          label: <Link to="/services/carpet-clean">Carpet Steam Cleaning</Link>,
          key: 'carpetclean',
        },
      ],
    },
    {
      label: <a href="https://askjay.trbdev.in/book/">Pricing & Book Online</a>,
      key: 'pricing',
    },
    {
      label: <Link to="/locations">Location</Link>,
      key: 'locations',
      children: [
        {
          label: <Link to="/locations/sydney">Sydney</Link>,
          key: 'sydney',
        },
        {
          label: <Link to="/locations/newcastle">Newcastle</Link>,
          key: 'newcastle',
        },
        {
          label: <Link to="/locations/central-coast">Central Coast</Link>,
          key: 'central-coast',
        },
        {
          label: <Link to="/locations/wollongong">Wollongong</Link>,
          key: 'wollongong',
        },
        {
          label: <Link to="/locations/maitland">Maitland</Link>,
          key: 'maitland',
        },
        {
          label: <Link to="/locations/tweed-heads">Tweed Heads</Link>,
          key: 'tweed-heads',
        },
        {
          label: <Link to="/locations/wagga-wagga">Wagga Wagga</Link>,
          key: 'wagga-wagga',
        },
        {
          label: <Link to="/locations/albury">Albury</Link>,
          key: 'albury',
        },
        {
          label: <Link to="/locations/port-macquarie">Port Macquarie</Link>,
          key: 'port-macquarie',
        },
        {
          label: <Link to="/locations/tamworth">Tamworth</Link>,
          key: 'tamworth',
        },
      ],
    },
    {
        label: <Link to="/about">About</Link>,
        key: 'about',
      },
      {
        label: <Link to="/jobs">Become a Cleaner</Link>,
        key: 'jobs',
      },
      {
        label: <Link to="/reviews">Reviews</Link>,
        key: 'reviews',
      },
      {
        label: <Link to="/faqs">FAQs</Link>,
        key: 'faqs',
      },
      {
        label: <Link to="/contact">Contact Us</Link>,
        key: 'contact',
      },
  ];

  return (
    <header className={`bg-white shadow-md ${isSticky ? 'sticky top-0 z-50' : ''}`}>
      <div className="container mx-auto py-3">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img src={logo} alt="Logo" width={200} />
            </Link>
          </div>
          <div className="hidden lg:flex items-center space-x-4">
            <a href="tel:1300520360" className="bg-white hover:bg-yellow-300 duration-300 text-black border border-yellow-400 px-4 py-3 rounded-md text-md font-medium">
            <FaPhoneVolume className='inline-block me-2' /> 1300 520 360
            </a>
            <a href="mailto:info@askjay.com.au" className="bg-white hover:bg-yellow-300 duration-300 text-black border border-yellow-400 px-4 py-3 rounded-md text-md font-medium">
            <FaEnvelope className='inline-block me-2' /> info@askjay.com.au
            </a>
            <a href="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-md font-medium">Get a quote</a>
            
          </div>
          <button 
            className="lg:hidden text-orange-500"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      <nav className={`bg-white border-t ${isMobile && !isMenuOpen ? 'hidden' : 'block'}`}>
        <div className="container mx-auto px-4 ">
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode={isMobile ? "inline" : "horizontal"}
            items={items}
            className="border-0 text-md font-medium justify-center"
          />
        </div>
      </nav>
    </header>
  );
};

export default Header;