import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { FaCheckCircle, FaArrowRight } from "react-icons/fa";
import { Row, Col } from "antd";
import { Link } from 'react-router-dom';
import KnowMore from '../../components/KnowMore';
import Testimonial from '../../components/Testimonial';
import { Form, Input, Button } from "antd";
import { RiArrowRightSLine } from 'react-icons/ri';
import Accordion from "../../components/Accordion";
import ServiceSlide from "../../components/ServiceSlide";

const LocationSingle = ({ locationData }) => {
  const onFinish = (values) => {
    console.log("Received values of form:", values);
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Locations", href: "/locations" },
    { label: locationData.breadTitle },
  ];
  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title={locationData.breadTitle}
        description={locationData.breadDescription}
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={locationData.backgroundImageSrc}
      />
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <h2 className="text-3xl md:text-4xl font-bold mb-4 md:pr-6">
              {locationData.whyHireUsTitle}
            </h2>
            <p className="text-gray-600 text-sm md:text-base lg:text-lg mb-6">
              {locationData.whyHireUsDescription}
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-5">
            {locationData.benefits.map((benefit, index) => (
              <div key={index} className="flex flex-col items-start shadow-md p-10 rounded-lg">
                <FaCheckCircle className="w-10 h-10 text-green-500 mb-2" />
                <h3 className="text-2xl mb-2 font-semibold">{benefit.title}</h3>
                <p className="text-lg text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="md:w-1/3">
              <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-5 sm:mb-8">
                {locationData.servicesTitle}
              </h2>
              <p className="text-sm sm:text-base mb-4 sm:mb-6">
                {locationData.servicesDescription}
              </p>
              <button className="flex items-center text-orange-500 hover:text-orange-600 transition-colors duration-300">
                <span className="font-semibold mr-1">How it works</span>
                <FaArrowRight className="w-4 h-4" />
              </button>
            </div>
            <div className="md:w-2/3 bg-gray-100 rounded-lg p-4">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-y-4 gap-x-5">
                {locationData.servicesList.map((service, index) => (
                  <div key={index} className="flex items-center">
                    <FaCheckCircle className="text-green-500 mr-2 h-5 w-5" />
                    <span className="text-md">{service}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto px-4">
          <ServiceSlide />
        </div>
      </div>
      <div className="bg-[#fcf9f6] py-16">
        <div className="container mx-auto px-4">
          <Row gutter={[32, 32]}>
            <Col xs={24} md={12}>
              <div className="sticky top-48 md:pr-20">
                <h2 className="text-4xl font-bold mb-6">{locationData.howToStartTitle}</h2>
                <p className="mb-6 text-lg">
                  {locationData.howToStartDescription}
                </p>
                <Link to="https://askjay.trbdev.in/book" className="bg-yellow-300 text-dark px-4 py-3 rounded-md text-sm font-medium">Book Your Service</Link>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <Row gutter={[16, 24]} className="mb-6">
                {locationData.steps.map((step, index) => (
                  <Col key={index} xs={24} sm={24} md={24} className="flex">
                    <div className="mr-3">
                      <span className="w-10 h-10 bg-green-500 text-white rounded-full flex items-center justify-center text-xl font-bold">
                        {step.number}
                      </span>
                    </div>
                    <div>
                      <h3 className="text-2xl font-semibold mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 text-lg">{step.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <Testimonial/>
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex">
            <div className="w-3/5">
              <div className="mb-12">
                <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-3 sm:mb-5">
                  {locationData.faqTitle}
                </h2>
                <p className="text-gray-600 mb-4 mb-5">
                  {locationData.faqDescription}
                </p>
                <Accordion items={locationData.faqItems} />
              </div>
              <Link to="/faqs" className="text-center text-dark text-xl flex justify-center items-center">Learn more about <span style={{ display: 'inline-block' }}><RiArrowRightSLine /></span></Link>
            </div>
            <div className="w-2/5 px-8">
              <div className="bg-gray-200 p-4">
                <h2 className="text-2xl font-bold mb-4">{locationData.contactFormTitle}</h2>
                <p className="mb-4">
                  {locationData.contactFormDescription}
                </p>
                
                <Form
                  name="contact_form"
                  onFinish={onFinish}
                  layout="vertical"
                  className="space-y-4"
                >
                  <Row gutter={{ xs: 8, sm: 16}}>
                    <Col className="gutter-row mb-0" span={12}>
                      <Form.Item
                      label="First Name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                      className="mb-3"
                    >
                      <Input className="h-11" />
                    </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your last name!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                    <Form.Item
                        label="Postcode"
                        name="postcode"
                        rules={[
                          {
                            required: true,
                            message: "Please input your postcode!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          { required: true, message: "Please input your email!" },
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        label="Message"
                        name="message"
                        rules={[
                          { required: true, message: "Please input your message!" },
                        ]}
                        className="mb-3"
                      >
                        <Input.TextArea rows={4} className="h-11"/>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Button type="primary" htmlType="submit" className="w-full">
                        Contact Us
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationSingle;