import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Accordion from '../components/Accordion';
import breadBgImageabt from '../images/FAQ-Page-image.jpg';

const Faqs = () => {
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Faqs" }
  ];
  const beforeHiring = [
    {
      title: "What services do you offer?",
      content:
        "We offer a comprehensive range of cleaning services, including regular household cleaning, deep cleaning, end-of-lease cleaning, carpet cleaning, pressure washing, and more. Whatever your cleaning needs, we have you covered!",
    },
    {
      title: "Are your cleaners trained and insured?",
      content:
        "Yes, all our cleaners are professionally trained, background-checked, and fully insured, giving you peace of mind that your home is in safe hands.",
    },
    {
      title: "How do I schedule a cleaning service?",
      content:
        "Booking a service is easy! You can schedule a cleaning through our website, mobile app, or simply give us a call. Choose a time and date that works for you, and we’ll handle the rest.",
    },
    {
        title: "Do I need to provide any cleaning supplies?",
        content:
          "No, our cleaners bring all necessary cleaning supplies and equipment. However, if you have specific products you’d like us to use, just let us know, and we’ll be happy to accommodate.",
      },
      {
        title: "How do you ensure the quality of the cleaning service?",
        content:
          "We take pride in delivering top-quality cleaning services. Our team follows a detailed checklist and performs regular quality checks. If you’re ever unsatisfied, we offer a 100% satisfaction guarantee and will revisit your home to make things right.",
      },
      {
        title: "Can I trust your cleaners with my home?",
        content:
          "Absolutely. We carefully vet each cleaner and ensure they undergo thorough background checks. Our team is trustworthy, reliable, and committed to respecting your space and privacy.",
      },
      {
        title: "What happens if I need to cancel or reschedule my cleaning appointment?",
        content:
          "We understand that plans can change. You can cancel or reschedule your appointment by contacting us at least 24 hours in advance without any extra charges.",
      },
    // Add more items as needed
  ];
  const duringHomeclean = [
    {
      title: "What services do you offer for home cleaning?",
      content: "We provide a variety of home cleaning services, including regular cleaning, deep cleaning, move-in/move-out cleaning, end-of-lease cleaning, and specialized services like carpet and upholstery cleaning.",
    },
    {
      title: "How do I schedule a cleaning service?",
      content: "You can schedule a cleaning service by visiting our website and filling out the booking form or by calling our customer service team. We recommend booking in advance to secure your preferred date and time.",
    },
    {
      title: "What should I do before the cleaning team arrives?",
      content: "We recommend tidying up personal items and valuables to help our cleaning team work efficiently. Please also inform us of any specific areas that require special attention.",
    },
    {
        title: "Are your cleaning products safe for my family and pets?",
        content:
          "Yes, we prioritize your health and safety by using eco-friendly and non-toxic cleaning products. If you have specific product preferences, please let us know during the booking process.",
      },
      {
        title: "What if I am not satisfied with the cleaning service?",
        content:
          "Customer satisfaction is our top priority. If you are not completely satisfied with our service, please contact us within 24 hours, and we will send a team back to address any concerns at no additional cost.",
      },
      {
        title: "Do I need to be home during the cleaning?",
        content:
          "It’s not necessary for you to be home during the cleaning. Many of our clients prefer to provide us with access to their home and run errands while we work. However, if you feel more comfortable being present, you are welcome to stay.",
      },
      {
        title: "What are your payment options?",
        content:
          "We accept various payment methods, including credit/debit cards, bank transfers, and cash. Payment details can be provided during the booking process or upon completion of the service.",
      },
  ];

  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="Frequently Asked Questions"
        description="Take a look at some of the frequently asked questions that we have received from our clients regarding our services. We have included videos and articles to give you a complete perspective of our home cleaning services. Can’t find your question? Feel free to contact us, we are happy to answer any of your questions and help you on your way to a clean home."
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImageabt}
      />
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
            <h5 className="text-3xl sm:text-xl font-bold font-semibold mb-3 sm:mb-5">
            At askJay, we understand that choosing a cleaning service can come with many questions and considerations. Whether you're curious about our cleaning methods, pricing, or scheduling, we’re here to provide you with the information you need to make an informed decision. Our dedicated team is committed to delivering exceptional service, and we aim to address your queries thoroughly to ensure you feel confident in choosing us for your cleaning needs. If you have additional questions that aren’t covered here, please don’t hesitate to reach out!
            </h5>
          <div className="mb-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-3 sm:mb-5">
            FAQs for Before Hiring a Cleaner
            </h2>
            <Accordion items={beforeHiring} />
          </div>
          <div className="mb-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-3 sm:mb-5">
              FAQs for During Home Cleaning
            </h2>
            <Accordion items={duringHomeclean} />
          </div>
          <div className="mb-12">
            <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-3 sm:mb-5">
              During Home Cleaning
            </h2>
            <p className="text-gray-600 mb-4 mb-5">
              It’s your first time bringing a cleaning service into your home.
              You’re excited, but are you prepared for their visit? Will the
              cleaner bring all of the supplies that they need, or am I supposed
              to supply them? What about my pets? There’s no reason to get
              worried, because we’ve thought of everything. Take a look at the
              links in this section to find out how you and your cleaner make
              arrangements for us to deliver the most efficient service.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;