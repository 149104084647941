import React, { useState } from 'react';
import { FaStar, FaSearch } from "react-icons/fa";
import bannerImage from "../images/Home-Page-image.jpg"; // Adjust the path as needed

const Banner = () => {
  const [postcode, setPostcode] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (postcode) {
      window.location.href = `https://askjay.trbdev.in/book/index.php?postcode=${postcode}`;
    }
  };
  return (
    <div
      className="relative overflow-hidden bg-cover bg-top bg-no-repeat py-[6rem]"
      style={{ backgroundImage: `url(${bannerImage})` }}
    >
      <div className="container mx-auto flex items-center">
        <div className="w-full md:w-2/3 z-10">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">
            #1 Trusted Company in Australia
          </h1>
          <p className="text-gray-600 mb-8">
            ASKJAY provides reliable and efficient daily household cleaning
            services tailored to your needs. Our team of experienced
            professionals ensures a spotless home every day. Enjoy peace of mind
            with our trusted and top-quality cleaning solutions.
          </p>
          <p className="text-gray-600 mb-8">
            We handle all household chores, from carpet cleaning to gardening.
            Each cleaning session is tailored to your specific requirements.
            Personalise your clean by adding any special instructions. Our
            seamless booking process makes customisation effortless. Get a clean
            that perfectly suits your home's unique needs.
          </p>
          <form
            onSubmit={handleSubmit}
            className="flex items-center bg-white rounded-lg overflow-hidden shadow-md mb-6"
          >
            <div className="flex-grow flex items-center px-4">
              <FaSearch className="text-gray-400 mr-2" />
              <input
                type="text"
                placeholder="Enter your postcode"
                className="w-full py-3 focus:outline-none"
                value={postcode}
                onChange={(e) => setPostcode(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="bg-yellow-300 text-dark px-6 py-3 font-medium hover:bg-yellow-400 transition duration-300"
            >
              Get Pricing & Book Online
            </button>
          </form>
        </div>
      </div>
      <div className="w-1/2 h-full"></div>
    </div>
  );
};

export default Banner;
