import React from "react";
import { Form, Input, Button, message } from "antd";
import { Col, Row } from "antd";
import Breadcrumb from "../components/Breadcrumb";
import Accordion from "../components/Accordion";
import breadBgImage from "../images/slider5.jpeg";
import { RiArrowRightSLine } from "react-icons/ri";
import Testimonial from "../components/Testimonial";
import { Link } from "react-router-dom";

const Contact = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    fetch('http://askjay.trbdev.in/contact/contact_us.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert('Your message has been sent!');
        } else {
          alert('There was an issue sending your message. Please try again.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        alert('There was an error sending your message.');
      });
  };
  

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Contact Us" },
  ];
  const accordionItems = [
    {
      title: "Why should I hire a home cleaner?",
      content:
        "Time is precious, and for many this means that the cost of hiring a cleaning company is minimal in comparison to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time with family, take part in events, or even earn more hours at work.",
    },
    {
      title: "What are the benefits of using a home cleaning agency?",
      content:
        "Home cleaning agencies offer professional services, reliable scheduling, and often have insurance and background checks for their employees.",
    },
    {
      title: "What are the benefits of using a home cleaning agency?",
      content:
        "Home cleaning agencies offer professional services, reliable scheduling, and often have insurance and background checks for their employees.",
    },
    {
      title: "Why should I hire a home cleaner?",
      content:
        "Time is precious, and for many this means that the cost of hiring a cleaning company is minimal in comparison to the value. Not only do you get a professionally cleaned home, but your schedule is now free to spend time with family, take part in events, or even earn more hours at work.",
    },
    {
      title: "What are the benefits of using a home cleaning agency?",
      content:
        "Home cleaning agencies offer professional services, reliable scheduling, and often have insurance and background checks for their employees.",
    },
    {
      title: "What are the benefits of using a home cleaning agency?",
      content:
        "Home cleaning agencies offer professional services, reliable scheduling, and often have insurance and background checks for their employees.",
    },
    // Add more items as needed
  ];

  return (
    <>
      <Breadcrumb
        breadcrumbItems={breadcrumbItems}
        title="Contact us"
        description="Have a question? Give our friendly team a call or use the enquiry form and a member of our team will get back to you."
        phoneNumber="1300 364 646"
        businessHours="Enquiries MON - FRI 8:30 AM - 5:00 PM AEST"
        backgroundImageSrc={breadBgImage}
      />
      <div className="bg-white py-16">
        <div className="container mx-auto px-4">
          <div className="flex">
            <div className="w-3/5">
              <div className="mb-12">
                <h2 className="text-3xl sm:text-4xl font-bold font-semibold mb-3 sm:mb-5">
                  Deciding to Hire a Cleaner
                </h2>
                <p className="text-gray-600 mb-4 mb-5">
                  Hiring a cleaner has been made easy and affordable with a
                  number of services available to suit your needs. Let us walk
                  you through just a few of the benefits you will enjoy by using
                  our home cleaning agency.
                </p>
                <Accordion items={accordionItems} />
              </div>
              <Link
                to="/faqs"
                className="text-center text-dark text-xl flex justify-center items-center"
              >
                Learn more about{" "}
                <span style={{ display: "inline-block" }}>
                  <RiArrowRightSLine />
                </span>
              </Link>
            </div>
            <div className="w-2/5 px-8">
              <div className="bg-gray-200 p-4">
                <h2 className="text-2xl font-bold mb-4">Prefer to email us?</h2>
                <p className="mb-4">
                  Send us a message and we'll get back to you!
                </p>

                <Form
                  form={form}
                  name="contact_form"
                  onFinish={onFinish}
                  layout="vertical"
                  className="space-y-4"
                >
                  <Row gutter={{ xs: 8, sm: 16 }}>
                    <Col className="gutter-row mb-0" span={12}>
                      <Form.Item
                        label="Your Name"
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                          {
                            pattern: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit phone number!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                      <Form.Item
                        label="Postcode"
                        name="postcode"
                        rules={[
                          {
                            required: true,
                            message: "Please input your postcode!",
                          },
                          {
                            pattern: /^[0-9]{6}$/,
                            message: "Please enter a valid 6-digit postcode!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input className="h-11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Form.Item
                        label="Message"
                        name="message"
                        rules={[
                          {
                            required: true,
                            message: "Please input your message!",
                          },
                          {
                            min: 10,
                            message:
                              "Message must be at least 10 characters long!",
                          },
                        ]}
                        className="mb-3"
                      >
                        <Input.TextArea rows={4} className="h-11" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="w-full h-11"
                      >
                        Contact Us
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Testimonial />
    </>
  );
};

export default Contact;
